import React, { useEffect, useState } from "react";
import { getApiUrl } from "../../utils/getApiUrl";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NewsCarousel.css"; 
import Loader from "../Loader"; 
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Import arrow icons

const NewsCarousel = () => {
  let apiUrl = getApiUrl();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/news`);
        const sortedNews = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNews(sortedNews.slice(0, 10));
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [apiUrl]);

  const truncate = (str, num) => (str.length <= num ? str : str.slice(0, num) + "...");

  // Custom Arrows
  const CustomPrevArrow = (props) => (
    <button {...props} className="custom-arrow prev-arrow">
      <FaArrowLeft />
    </button>
  );

  const CustomNextArrow = (props) => (
    <button {...props} className="custom-arrow next-arrow">
      <FaArrowRight />
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    adaptiveHeight: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    cssEase: "cubic-bezier(0.86, 0, 0.07, 1)",
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[50vh]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="news-carousel-container">
      <Slider {...settings}>
        {news.map((item) => (
          <div key={item._id} className="news-item">
            <div className="news-content-container">
              <div className="news-image-container">
                <img src={`${apiUrl}/${item.image}`} alt={item.title} className="news-image" />
                <div className="news-overlay">
                  <h2 className="news-title">{truncate(item.title, 200)}</h2>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NewsCarousel;
