import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Table, Modal, message } from "antd";
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const FeedbackManager = () => {
  const { t } = useTranslation(); // Initialize useTranslation hook
  let apiUrl = getApiUrl();
  const [feedbacks, setFeedbacks] = useState([]);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState(null);

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const fetchFeedbacks = async () => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      const response = await axios.get(`${apiUrl}/api/feedback`, { headers });
      setFeedbacks(response.data);
    } catch (error) {
      console.error(t("feedbackManager.fetchError"), error);
      message.error(t("feedbackManager.fetchErrorMessage"));
    }
  };

  const handleView = (record) => {
    setCurrentFeedback(record);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsViewModalVisible(false);
    setCurrentFeedback(null);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      await axios.delete(`${apiUrl}/api/feedback/${id}`, { headers });

      message.success(t("feedbackManager.deleteSuccess"));
      fetchFeedbacks(); // Refresh the list
    } catch (error) {
      console.error(t("feedbackManager.deleteError"), error);
      message.error(t("feedbackManager.deleteErrorMessage"));
    }
  };

  const columns = [
    {
      title: t("feedbackManager.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("feedbackManager.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("feedbackManager.message"),
      dataIndex: "message",
      key: "message",
      render: (text) => text.substring(0, 20) + (text.length > 20 ? "..." : ""),
    },
    {
      title: t("feedbackManager.date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: t("feedbackManager.actions"),
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="default"
            className="bg-blue-600 p-2 text-white"
            onClick={() => handleView(record)}
          >
            {t("feedbackManager.view")}
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Table
        columns={columns}
        dataSource={feedbacks}
        rowKey="_id"
        style={{ marginTop: 20 }}
      />

      <Modal
        title={t("feedbackManager.viewFeedback")}
        visible={isViewModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("feedbackManager.close")}
          </Button>,
        ]}
      >
        {currentFeedback && (
          <div>
            <p>
              <strong>{t("feedbackManager.name")}:</strong> {currentFeedback.name}
            </p>
            <p>
              <strong>{t("feedbackManager.email")}:</strong> {currentFeedback.email}
            </p>
            <p>
              <strong>{t("feedbackManager.message")}:</strong>
            </p>
            <p>{currentFeedback.message}</p>
            <p>
              <strong>{t("feedbackManager.createdAt")}:</strong>{" "}
              {new Date(currentFeedback.createdAt).toLocaleString()}
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default FeedbackManager;