import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { getApiUrl } from "../../utils/getApiUrl";

const ComplaintManager = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  let apiUrl = getApiUrl();
  const [complaints, setComplaints] = useState([]);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentComplaint, setCurrentComplaint] = useState(null);
  const [editId, setEditId] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    fetchComplaints();
  }, []);

  const fetchComplaints = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${apiUrl}/api/complaints`, { headers });
      setComplaints(response.data);
    } catch (error) {
      console.error(t('complaintManager.fetchError'), error);
      message.error(t('complaintManager.fetchErrorMessage'));
    }
  };

  const handleView = (record) => {
    setCurrentComplaint(record);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsViewModalVisible(false);
    setCurrentComplaint(null);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/api/complaints/${id}`, { headers });
      message.success(t('complaintManager.deleteSuccess'));
      fetchComplaints();
    } catch (error) {
      console.error(t('complaintManager.deleteError'), error);
      message.error(t('complaintManager.deleteErrorMessage'));
    }
  };

  const handleUpdateSubmit = async (values) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      const formData = new FormData();
      formData.append("complaintName", values.complaintName);
      formData.append("complaintSubCity", values.complaintSubCity);
      formData.append("complaintWoreda", values.complaintWoreda);
      formData.append("complaintHouseNumber", values.complaintHouseNumber);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("complaintInstitution", values.complaintInstitution);
      formData.append("complaintDetail", values.complaintDetail);
      formData.append("complaintSolution", values.complaintSolution);
      if (fileList.length > 0) {
        formData.append("evidence", fileList[0].originFileObj);
      }

      await axios.patch(`${apiUrl}/api/complaints/${editId}`, formData, { headers });
      message.success(t('complaintManager.updateSuccess'));
      setEditId(null);
      fetchComplaints();
    } catch (error) {
      console.error(t('complaintManager.updateError'), error);
      message.error(t('complaintManager.updateErrorMessage'));
    }
  };

  const columns = [
    {
      title: t('complaintManager.columns.name'),
      dataIndex: 'complaintName',
      key: 'complaintName',
    },
    {
      title: t('complaintManager.columns.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: t('complaintManager.columns.complaintDetail'),
      dataIndex: 'complaintDetail',
      key: 'complaintDetail',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: t('complaintManager.columns.dateSubmitted'),
      dataIndex: 'dateSubmitted',
      key: 'complaintFiled',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: t('complaintManager.columns.actions'),
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => handleView(record)}>
            {t('complaintManager.actions.view')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Table columns={columns} dataSource={complaints} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title={t('complaintManager.modal.title')}
        visible={isViewModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>{t('complaintManager.modal.close')}</Button>,
        ]}
      >
        {currentComplaint && (
          <div>
            <p><strong>{t('complaintManager.modal.name')}:</strong> {currentComplaint.complaintName}</p>
            <p><strong>{t('complaintManager.modal.phoneNumber')}:</strong> {currentComplaint.phoneNumber}</p>
            <p><strong>{t('complaintManager.modal.subCity')}:</strong> {currentComplaint.complaintSubCity}</p>
            <p><strong>{t('complaintManager.modal.woreda')}:</strong> {currentComplaint.complaintWoreda}</p>
            <p><strong>{t('complaintManager.modal.houseNumber')}:</strong> {currentComplaint.complaintHouseNumber}</p>
            <p><strong>{t('complaintManager.modal.institution')}:</strong> {currentComplaint.complaintInstitution}</p>
            <p><strong>{t('complaintManager.modal.complaintDetail')}:</strong> {currentComplaint.complaintDetail}</p>
            <p><strong>{t('complaintManager.modal.complaintSolution')}:</strong> {currentComplaint.complaintSolution}</p>
            <p><strong>{t('complaintManager.modal.dateSubmitted')}:</strong> {new Date(currentComplaint.dateSubmitted).toLocaleString()}</p>
            {currentComplaint.evidence && (
              <div>
                <strong>{t('complaintManager.modal.evidence')}:</strong>
                <div>
                  {Array.isArray(currentComplaint.evidence) ? (
                    currentComplaint.evidence.map((evidence, index) => (
                      <div key={index} className="mb-2">
                        {evidence.endsWith('.jpg') || evidence.endsWith('.png') ? (
                          <img src={`${apiUrl}/${evidence}`} alt={`Evidence ${index + 1}`} style={{ maxWidth: '100%', maxHeight: 300 }} />
                        ) : evidence.endsWith('.mp4') ? (
                          <video controls src={`${apiUrl}/${evidence}`} style={{ maxWidth: '100%', maxHeight: 300 }} />
                        ) : evidence.endsWith('.mp3') ? (
                          <audio controls src={`${apiUrl}/${evidence}`} style={{ maxWidth: '100%', maxHeight: 300 }} />
                        ) : (
                          <a href={`${apiUrl}/${evidence}`} target="_blank" rel="noopener noreferrer">{t('complaintManager.modal.downloadEvidence')} {index + 1}</a>
                        )}
                      </div>
                    ))
                  ) : (
                    <div>
                      {currentComplaint.evidence.endsWith('.jpg') || currentComplaint.evidence.endsWith('.png') ? (
                        <img src={`${apiUrl}/${currentComplaint.evidence}`} alt="Evidence" style={{ maxWidth: '100%', maxHeight: 300 }} />
                      ) : currentComplaint.evidence.endsWith('.mp4') ? (
                        <video controls src={`${apiUrl}/${currentComplaint.evidence}`} style={{ maxWidth: '100%', maxHeight: 300 }} />
                      ) : currentComplaint.evidence.endsWith('.mp3') ? (
                        <audio controls src={`${apiUrl}/${currentComplaint.evidence}`} style={{ maxWidth: '100%', maxHeight: 300 }} />
                      ) : (
                        <a href={`${apiUrl}/${currentComplaint.evidence}`} target="_blank" rel="noopener noreferrer">{t('complaintManager.modal.downloadEvidence')}</a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ComplaintManager;