import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import { Card, Button, Pagination, Alert } from "antd";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import "tailwindcss/tailwind.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader"; // Import your custom Loader component

const NewsDetail = () => {
  let apiUrl = getApiUrl();
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [recentNews, setRecentNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 3; // Number of recent news items per page
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const fetchNewsItem = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/news/${id}`);
        setNewsItem(response.data);
      } catch (error) {
        console.error('Error fetching news item:', error);
        setError('Failed to fetch news item.');
      }
    };

    const fetchRecentNews = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/news`);
        setRecentNews(response.data.filter((item) => item._id !== id));
      } catch (error) {
        console.error('Error fetching recent news:', error);
        setError('Failed to fetch recent news.');
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchNewsItem(), fetchRecentNews()]);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRecentNews = recentNews.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <Loader />; // Use your custom Loader component here
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Alert message={error} type="error" />
      </div>
    );
  }

  if (!newsItem) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        No news item found
      </div>
    );
  }

  return (
    <div className="min-h-screen p-6 bg-gradient-to-r from-blue-50 to-blue-100">
      <div className="max-w-7xl mx-auto">
        {/* News Detail Card */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          data-aos="fade-up"
        >
          <Card
            cover={
              <img
                alt={newsItem.title}
                src={`${apiUrl}/${newsItem.image}`}
                className="w-full h-[70vh] object-fill rounded-t-lg"
              />
            }
            className="shadow-lg rounded-lg bg-white"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Meta
              title={
                <h1 className="text-3xl font-bold text-blue-800 mb-4">
                  {newsItem.title}
                </h1>
              }
              description={
                <div>
                  <p className="text-gray-700 text-lg mb-6">
                    {newsItem.description}
                  </p>
                  <p className="text-sm text-gray-500 mb-6">
                    {new Date(newsItem.date).toLocaleDateString("en-GB")}
                  </p>
                  <Link to="/news">
                    <Button
                      type="primary"
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors"
                    >
                      {t("Back to News")}
                    </Button>
                  </Link>
                </div>
              }
            />
          </Card>
        </motion.div>

        {/* Recent News Section */}
        <div className="mt-12">
          <h2
            className="text-3xl font-bold text-blue-800 mb-8"
            data-aos="fade-up"
          >
            {t("Recent News")}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {currentRecentNews.map((item, index) => (
              <motion.div
                key={item._id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                data-aos="fade-up"
              >
                <Card
                  cover={
                    <img
                      alt={item.title}
                      src={`${apiUrl}/${item.image}`}
                      className="w-full h-56 object-fill rounded-t-lg"
                    />
                  }
                  className="shadow-lg rounded-lg bg-white hover:shadow-xl transition-shadow"
                  style={{ borderRadius: "10px", overflow: "hidden" }}
                >
                  <Card.Meta
                    title={
                      <h4 className="text-xl font-semibold text-blue-800 mb-2">
                        {item.title}
                      </h4>
                    }
                    description={
                      <div>
                        <p className="text-gray-600 mb-4">
                          {item.description.length > 100
                            ? `${item.description.substring(0, 100)}...`
                            : item.description}
                        </p>
                        <p className="text-sm text-gray-500 mb-4">
                          {new Date(item.date).toLocaleDateString("en-GB")}
                        </p>
                        <Button
                          type="link"
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Link to={`/news/${item._id}`}>{t("Read More")}</Link>
                        </Button>
                      </div>
                    }
                  />
                </Card>
              </motion.div>
            ))}
          </div>
          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              total={recentNews.length}
              pageSize={itemsPerPage}
              onChange={onPageChange}
              showSizeChanger={false}
              className="bg-white rounded-lg p-2 shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;