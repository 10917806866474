import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getApiUrl } from "../../utils/getApiUrl";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const VacancyManager = () => {
  const { t } = useTranslation(); // Initialize useTranslation hook
  let apiUrl = getApiUrl();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [vacancies, setVacancies] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentVacancy, setCurrentVacancy] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() =>  { 
    fetchVacancies();
  }, []);

  const fetchVacancies = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${apiUrl}/api/vacancies`, { headers });
      setVacancies(response.data);
    } catch (error) {
      console.error(t('vacancyManager.fetchVacanciesError'), error);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('expiredDate', values.expiredDate.format('YYYY-MM-DD'));
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }

    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      if (editId) {
        await axios.patch(`${apiUrl}/api/vacancies/${editId}`, formData, { headers });
        message.success(t('vacancyManager.updateSuccess'));
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/vacancies`, formData, { headers });
        message.success(t('vacancyManager.addSuccess'));
      }
      form.resetFields();
      setFileList([]);
      fetchVacancies();
    } catch (error) {
      console.error(t('vacancyManager.submitError'), error);
      message.error(editId ? t('vacancyManager.updateFailed') : t('vacancyManager.addFailed'));
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.image = imageFile;

      const formData = new FormData();
      formData.append("title", updatedData.title);
      formData.append("description", updatedData.description);
      formData.append("expiredDate", updatedData.expiredDate.format('YYYY-MM-DD'));
      if (imageFile) formData.append("image", updatedData.image);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      await axios.patch(`${apiUrl}/api/vacancies/${currentVacancy._id}`, formData, { headers });

      message.success(t('vacancyManager.updateSuccess'));
      setIsModalVisible(false);
      fetchVacancies();
    } catch (error) {
      console.error(t('vacancyManager.validationFailed'), error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      title: record.title,
      description: record.description,
      expiredDate: moment(record.expiredDate),
    });
    setCurrentVacancy(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/api/vacancies/${id}`, { headers });
      message.success(t('vacancyManager.deleteSuccess'));
      fetchVacancies();
    } catch (error) {
      console.error(t('vacancyManager.deleteError'), error);
      message.error(t('vacancyManager.deleteFailed'));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentVacancy(null);
    setImageFile(null);
  };

  const viewVacancy = (record) => {
    Modal.info({
      title: t('vacancyManager.viewVacancyTitle'),
      content: (
        <div>
          <p><strong>{t('vacancyManager.title')}:</strong> {record.title}</p>
          <p><strong>{t('vacancyManager.postDate')}:</strong> {moment(record.postDate).format('YYYY-MM-DD')}</p>
          <p><strong>{t('vacancyManager.expiredDate')}:</strong> {moment(record.expiredDate).format('YYYY-MM-DD')}</p>
          <p><strong>{t('vacancyManager.description')}:</strong> {record.description}</p>
          {record.image && <p><strong>{t('vacancyManager.image')}:</strong> <img src={`${apiUrl}/${record.image}`} alt={record.title} style={{ width: '100%', maxHeight: '400px' }} /></p>}
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t('vacancyManager.confirmDeleteTitle'),
      content: t('vacancyManager.confirmDeleteContent'),
      okText: t('vacancyManager.delete'),
      okType: 'danger',
      cancelText: t('vacancyManager.cancel'),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: t('vacancyManager.title'),
      dataIndex: 'title',
      key: 'title',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: t('vacancyManager.postDate'),
      dataIndex: 'postDate',
      key: 'postDate',
      render: (date) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: t('vacancyManager.expiredDate'),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      render: (date) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: t('vacancyManager.actions'),
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewVacancy(record)} style={{ marginRight: 8 }}>
            {t('vacancyManager.view')}
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            {t('vacancyManager.update')}
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            {t('vacancyManager.delete')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="title" label={t('vacancyManager.title')} rules={[{ required: true, message: t('vacancyManager.titleRequired') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label={t('vacancyManager.description')} rules={[{ required: true, message: t('vacancyManager.descriptionRequired') }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="expiredDate" label={t('vacancyManager.expiredDate')} rules={[{ required: true, message: t('vacancyManager.expiredDateRequired') }]}>
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item label={t('vacancyManager.image')}>
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>{t('vacancyManager.uploadImage')}</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">{t('vacancyManager.submit')}</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={vacancies} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title={t('vacancyManager.updateVacancyTitle')}
        visible={isModalVisible}
        onOk={handleUpdateSubmit}
        onCancel={handleCancel}
      >
        <Form form={updateForm} layout="vertical" initialValues={currentVacancy}>
          <Form.Item name="title" label={t('vacancyManager.title')} rules={[{ required: true, message: t('vacancyManager.titleRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label={t('vacancyManager.description')} rules={[{ required: true, message: t('vacancyManager.descriptionRequired') }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="expiredDate" label={t('vacancyManager.expiredDate')} rules={[{ required: true, message: t('vacancyManager.expiredDateRequired') }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item label={t('vacancyManager.updateImage')}>
            <input type="file" onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VacancyManager;