import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Upload,
  Button,
  Form,
  Input,
  message,
  Table,
  Modal,
  Image,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getApiUrl } from "../../utils/getApiUrl";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const NewsManager = () => {
  const { t } = useTranslation(); // Initialize the t function
  let apiUrl = getApiUrl();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [news, setNews] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/news`);
      setNews(response.data);
    } catch (error) {
      console.error(t("errorFetchingNews"), error);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    if (fileList.length > 0) {
      formData.append("image", fileList[0].originFileObj);
    }

    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      if (editId) {
        await axios.patch(`${apiUrl}/api/news/${editId}`, formData, {
          headers,
        });
        message.success(t("newsUpdatedSuccessfully"));
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/news`, formData, { headers });
        message.success(t("newsAddedSuccessfully"));
      }
      form.resetFields();
      setFileList([]);
      fetchNews();
    } catch (error) {
      console.error(t("errorSubmittingNewsData"), error);
      message.error(
        editId ? t("failedToUpdateNews") : t("failedToAddNews")
      );
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.image = imageFile;

      const formData = new FormData();
      formData.append("title", updatedData.title);
      formData.append("description", updatedData.description);
      if (imageFile) formData.append("image", updatedData.image);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      await axios.patch(`${apiUrl}/api/news/${currentNews._id}`, formData, {
        headers,
      });

      message.success(t("newsUpdatedSuccessfully"));
      setIsModalVisible(false);
      fetchNews();
    } catch (error) {
      console.error(t("validationFailed"), error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      title: record.title,
      description: record.description,
    });
    setCurrentNews(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/api/news/${id}`, { headers });

      message.success(t("newsDeletedSuccessfully"));
      fetchNews();
    } catch (error) {
      console.error(t("errorDeletingNews"), error);
      message.error(t("failedToDeleteNews"));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentNews(null);
    setImageFile(null);
  };

  const viewMessage = (record) => {
    Modal.info({
      title: t("viewNews"),
      content: (
        <div>
          <p>
            <strong>{t("title")}:</strong> {record.title}
          </p>
          <p>
            <strong>{t("description")}:</strong> {record.description}
          </p>
          <p>
            <strong>{t("image")}:</strong>{" "}
            {record.image ? (
              <img
                src={`${apiUrl}/${record.image}`}
                alt={record.title}
                style={{ width: "100%", maxHeight: "400px" }}
              />
            ) : (
              t("noImage")
            )}
          </p>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t("confirmDelete"),
      content: t("confirmDeleteContent"),
      okText: t("delete"),
      okType: "danger",
      cancelText: t("cancel"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      render: (text) => text.substring(0, 20) + (text.length > 20 ? "..." : ""),
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      render: (text) => text.substring(0, 40) + (text.length > 40 ? "..." : ""),
    },
    {
      title: t("image"),
      dataIndex: "image",
      key: "image",
      render: (image) =>
        image ? (
          <Image
            width={100}
            src={`${apiUrl}/${image}`}
            alt="News Image"
            fallback="default-image-url"
          />
        ) : (
          t("noImage")
        ),
    },
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: t("actions"),
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            type="default"
            className="bg-blue-600 p-2 text-white"
            onClick={() => viewMessage(record)}
            style={{ marginRight: 8 }}
          >
            {t("view")}
          </Button>
          <Button
            type="default"
            className="bg-green-700 p-2 text-white"
            onClick={() => handleUpdate(record)}
            style={{ marginRight: 8 }}
          >
            {t("update")}
          </Button>
          <Button
            type="default"
            className="bg-red-600 p-2 text-white"
            onClick={() => confirmDelete(record._id)}
            style={{ color: "white" }}
          >
            {t("delete")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label={t("title")}
          rules={[{ required: true, message: t("pleaseInputTitle") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("description")}
          rules={[{ required: true, message: t("pleaseInputDescription") }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label={t("image")}>
          <Upload
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>{t("uploadImage")}</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={news}
        rowKey="_id"
        style={{ marginTop: 20 }}
      />

      <Modal
        title={t("updateNews")}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item
            name="title"
            label={t("title")}
            rules={[{ required: true, message: t("pleaseInputTitle") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t("description")}
            rules={[{ required: true, message: t("pleaseInputDescription") }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label={t("image")}>
            <input type="file" onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default NewsManager;