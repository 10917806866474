import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import News from './pages/News';
import Gallery from './pages/Gallery';
import Management from './pages/Management';
import ChiefExecutive from './pages/ManDropdown/ChiefExecutive';
import Cabinets from './pages/ManDropdown/Cabinets';
import Structure from './pages/ManDropdown/Structure';
import Woredas from './pages/ManDropdown/Woredas';
import WoredaDetail from './components/ManagementDD/Woredas/WoredasDetail';
import OfficeDetail from './components/ManagementDD/Offices/OfficeDetail';
import Offices from './pages/ManDropdown/Offices';
import Announcement from './pages/Announcement';
import Tender from './pages/AnnDropdown/Tender';
import Vacancy from './pages/AnnDropdown/Vacancy';
import Event from './pages/AnnDropdown/Event';
import MainLayout from './pages/MainLayout';
import ErrorPage from './pages/ErrorPage';
import Contact from './pages/Contact';
import Compliant from './pages/Compliant';
import AuthHandler from './components/Admin/AuthHandler';
import NewsDetail from './components/News/NewsDesc';
import NavbarA from './components/Admin/NavbarA';
import ProtectedRoute from './components/Admin/ProtectedRoute';
import DetailedGallery from './components/Gallery/DetailedGallery';
import TopPerformers from './pages/TopPerformers';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (token && role) {
      setIsAuthenticated(true);
      setUserRole(role);
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        { path: "/", element: <Home /> },
        { path: "about", element: <About /> },
        { path: "news", element: <News /> },
        { path: "news/:id", element: <NewsDetail /> },
        { path: "gallery", element: <Gallery /> },
        { path: "gallery/:id", element: <DetailedGallery /> },
        { path: "management", element: <Management /> },
        { path: "management/chief-executive", element: <ChiefExecutive /> },
        { path: "management/cabinets", element: <Cabinets /> },
        { path: "management/structure", element: <Structure /> },
        { path: "management/woredas", element: <Woredas /> },
        { path: "management/woredas/:id", element: <WoredaDetail /> },
        { path: "management/offices", element: <Offices /> },
        { path: "management/offices/:id", element: <OfficeDetail /> },
        { path: "announcement", element: <Announcement /> },
        { path: "announcement/tender", element: <Tender /> },
        { path: "announcement/vacancy", element: <Vacancy /> },
        { path: "announcement/event", element: <Event /> },
        { path: "contact", element: <Contact /> },
        { path: "compliant", element: <Compliant /> },
        { path: "topperformers", element: <TopPerformers /> },
        {
          path: "adminpage",
          element: (
            <ProtectedRoute
              element={<NavbarA />}
              isAuthenticated={isAuthenticated}
              userRole={userRole}
            />
          ),
        },
        {
          path: "login",
          element: (
            <AuthHandler
              onLogin={(role) => {
                setIsAuthenticated(true);
                setUserRole(role);
              }}
            />
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
