import React, { useState, useEffect } from "react";
import axios from "axios";
import { Upload, Button, Form, Input, message, Table, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getApiUrl } from "../../utils/getApiUrl";
import { useTranslation } from "react-i18next"; // Import useTranslation

const DirectorMessageManager = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  let apiUrl = getApiUrl();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [editId, setEditId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [updateForm] = Form.useForm();

  useEffect(() => {
    fetchDirectorMessages();
  }, []);

  const fetchDirectorMessages = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/director`);
      setMessages(response.data);
    } catch (error) {
      console.error(t("directorMessageManager.fetchError"), error);
    }
  };

  const deleteDirectorMessage = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/api/director/${id}`, { headers });
      fetchDirectorMessages();
      message.success(t("directorMessageManager.deleteSuccess"));
    } catch (error) {
      console.error(t("directorMessageManager.deleteError"), error);
      message.error(t("directorMessageManager.deleteFailed"));
    }
  };

  const updateDirectorMessage = async (id, updatedData) => {
    try {
      const formData = new FormData();
      formData.append("title", updatedData.title);
      formData.append("description", updatedData.description);
      formData.append("ceoName", updatedData.ceoName);
      formData.append("ceoPosition", updatedData.ceoPosition);
      if (updatedData.image) {
        formData.append("image", updatedData.image);
      }

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      await axios.patch(`${apiUrl}/api/director/${id}`, formData, { headers });
      fetchDirectorMessages();
      message.success(t("directorMessageManager.updateSuccess"));
    } catch (error) {
      console.error(t("directorMessageManager.updateError"), error);
      message.error(t("directorMessageManager.updateFailed"));
    }
  };

  const handleUpdate = (record) => {
    updateForm.resetFields();
    setCurrentMessage({ ...record });
    setIsModalVisible(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) {
        updatedData.image = imageFile;
      }
      updateDirectorMessage(currentMessage._id, updatedData);
      setIsModalVisible(false);
      setCurrentMessage(null);
      setImageFile(null);
    } catch (error) {
      console.error(t("directorMessageManager.validationFailed"), error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentMessage(null);
    setImageFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("ceoName", values.ceoName);
    formData.append("ceoPosition", values.ceoPosition);
    if (fileList.length > 0) {
      formData.append("image", fileList[0].originFileObj);
    }

    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      let response;
      if (editId) {
        response = await axios.patch(
          `${apiUrl}/api/director/${editId}`,
          formData,
          {
            headers,
          }
        );
        setEditId(null);
      } else {
        response = await axios.post(`${apiUrl}/api/director`, formData, {
          headers,
        });
      }

      message.success(
        editId
          ? t("directorMessageManager.updateSuccess")
          : t("directorMessageManager.addSuccess")
      );
      form.resetFields();
      setFileList([]);
      fetchDirectorMessages();
    } catch (error) {
      console.error(t("directorMessageManager.submitError"), error);
      message.error(
        editId
          ? t("directorMessageManager.updateFailed")
          : t("directorMessageManager.addFailed")
      );
    }
  };

  const handleImageChange = ({ fileList }) => {
    setFileList(fileList.slice(-1));
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const columns = [
    {
      title: t("directorMessageManager.columns.title"),
      dataIndex: "title",
      key: "title",
      render: (text) => truncateText(text, 20),
    },
    {
      title: t("directorMessageManager.columns.description"),
      dataIndex: "description",
      key: "description",
      render: (text) => truncateText(text, 40),
    },
    {
      title: t("directorMessageManager.columns.ceoName"),
      dataIndex: "ceoName",
      key: "ceoName",
    },
    {
      title: t("directorMessageManager.columns.ceoPosition"),
      dataIndex: "ceoPosition",
      key: "ceoPosition",
    },
    {
      title: t("directorMessageManager.columns.actions"),
      key: "actions",
      render: (text, record) => (
        <div>
          <Button
            type="default"
            className="bg-blue-600 p-2 text-white"
            onClick={() => viewMessage(record)}
            style={{ marginRight: 8 }}
          >
            {t("directorMessageManager.actions.view")}
          </Button>
          <Button
            type="default"
            className="bg-green-700 p-2 text-white"
            onClick={() => handleUpdate(record)}
            style={{ marginRight: 8 }}
          >
            {t("directorMessageManager.actions.update")}
          </Button>
          <Button
            type="default"
            className="bg-red-600 p-2 text-white"
            onClick={() => confirmDelete(record._id)}
            style={{ color: "white" }}
          >
            {t("directorMessageManager.actions.delete")}
          </Button>
        </div>
      ),
    },
  ];

  const viewMessage = (record) => {
    Modal.info({
      title: t("directorMessageManager.viewModal.title"),
      content: (
        <div>
          <p>
            <strong>{t("directorMessageManager.viewModal.titleLabel")}:</strong>{" "}
            {record.title}
          </p>
          <p>
            <strong>{t("directorMessageManager.viewModal.descriptionLabel")}:</strong>{" "}
            {record.description}
          </p>
          <p>
            <strong>{t("directorMessageManager.viewModal.ceoNameLabel")}:</strong>{" "}
            {record.ceoName}
          </p>
          <p>
            <strong>{t("directorMessageManager.viewModal.ceoPositionLabel")}:</strong>{" "}
            {record.ceoPosition}
          </p>
          <p>
            <strong>{t("directorMessageManager.viewModal.imageLabel")}:</strong>{" "}
            <img
              src={`${apiUrl}/${record.image}`}
              alt={record.title}
              style={{ width: "100%", maxHeight: "400px" }}
            />
          </p>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t("directorMessageManager.deleteModal.title"),
      content: t("directorMessageManager.deleteModal.content"),
      okText: t("directorMessageManager.deleteModal.okText"),
      okType: "danger",
      cancelText: t("directorMessageManager.deleteModal.cancelText"),
      onOk() {
        deleteDirectorMessage(id);
      },
    });
  };

  return (
    <div className="w-full mx-auto p-4">
      <div className="w-full mx-auto mb-8">
        <h1 className="text-2xl font-bold mb-4 text-blue-800">
          {t("directorMessageManager.addTitle")}
        </h1>
        <Form form={form} onFinish={handleSubmit} className="space-y-4">
          <Form.Item
            name="title"
            label={t("directorMessageManager.form.titleLabel")}
            labelCol={{ span: 24 }}
            rules={[
              { required: true, message: t("directorMessageManager.form.titleRequired") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t("directorMessageManager.form.descriptionLabel")}
            labelCol={{ span: 24 }}
            rules={[
              { required: true, message: t("directorMessageManager.form.descriptionRequired") },
            ]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item
            name="ceoName"
            label={t("directorMessageManager.form.ceoNameLabel")}
            labelCol={{ span: 24 }}
            rules={[
              { required: true, message: t("directorMessageManager.form.ceoNameRequired") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ceoPosition"
            label={t("directorMessageManager.form.ceoPositionLabel")}
            labelCol={{ span: 24 }}
            rules={[
              { required: true, message: t("directorMessageManager.form.ceoPositionRequired") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="image" label={t("directorMessageManager.form.imageLabel")} labelCol={{ span: 24 }}>
            <Upload
              listType="picture"
              beforeUpload={() => false}
              onChange={handleImageChange}
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />}>{t("directorMessageManager.form.uploadButton")}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editId ? t("directorMessageManager.form.updateButton") : t("directorMessageManager.form.addButton")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="w-full mx-auto">
        <h1 className="text-2xl font-bold mb-4 text-blue-800">
          {t("directorMessageManager.listTitle")}
        </h1>
        <Table columns={columns} dataSource={messages} rowKey="_id" />
      </div>
      <Modal
        title={t("directorMessageManager.updateModal.title")}
        visible={isModalVisible}
        onOk={handleUpdateSubmit}
        onCancel={handleCancel}
      >
        <Form
          form={updateForm}
          initialValues={{
            title: currentMessage?.title,
            description: currentMessage?.description,
            ceoName: currentMessage?.ceoName,
            ceoPosition: currentMessage?.ceoPosition,
          }}
        >
          <Form.Item name="title" label={t("directorMessageManager.form.titleLabel")}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label={t("directorMessageManager.form.descriptionLabel")}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="ceoName" label={t("directorMessageManager.form.ceoNameLabel")}>
            <Input />
          </Form.Item>
          <Form.Item name="ceoPosition" label={t("directorMessageManager.form.ceoPositionLabel")}>
            <Input />
          </Form.Item>
          <Form.Item name="image" label={t("directorMessageManager.form.imageLabel")}>
            <Upload
              listType="picture"
              beforeUpload={() => false}
              onChange={handleFileChange}
            >
              <Button icon={<UploadOutlined />}>{t("directorMessageManager.form.uploadButton")}</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DirectorMessageManager;