import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Upload,
  Button,
  Form,
  Input,
  message,
  Table,
  Modal,
  Image,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import { useTranslation } from "react-i18next"; // Import useTranslation

const CabinetManager = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  let apiUrl = getApiUrl();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [cabinets, setCabinets] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCabinet, setCurrentCabinet] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchCabinets();
  }, []);

  const fetchCabinets = async () => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };

      const response = await axios.get(`${apiUrl}/api/cabinets`, { headers });
      setCabinets(response.data);
    } catch (error) {
      console.error("Error fetching cabinets:", error);
      message.error(t("fetchError")); // Translated error message
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("title", values.title);
    if (fileList.length > 0) {
      formData.append("img", fileList[0].originFileObj);
    }

    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };

      if (editId) {
        await axios.patch(`${apiUrl}/api/cabinets/${editId}`, formData, {
          headers,
        });
        message.success(t("updateSuccess")); // Translated success message
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/cabinets`, formData, { headers });
        message.success(t("addSuccess")); // Translated success message
      }
      form.resetFields();
      setFileList([]);
      fetchCabinets();
    } catch (error) {
      console.error("Error submitting cabinet data:", error);
      message.error(
        editId ? t("updateError") : t("addError") // Translated error messages
      );
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.img = imageFile;

      const formData = new FormData();
      formData.append("name", updatedData.name);
      formData.append("title", updatedData.title);
      if (imageFile) formData.append("img", updatedData.img);

      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      await axios.patch(
        `${apiUrl}/api/cabinets/${currentCabinet._id}`,
        formData,
        { headers }
      );

      message.success(t("updateSuccess")); // Translated success message
      setIsModalVisible(false);
      fetchCabinets();
    } catch (error) {
      console.error("Error updating cabinet:", error);
      message.error(t("updateError")); // Translated error message
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      name: record.name,
      title: record.title,
    });
    setCurrentCabinet(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      await axios.delete(`${apiUrl}/api/cabinets/${id}`, { headers });

      message.success(t("deleteSuccess")); // Translated success message
      fetchCabinets();
    } catch (error) {
      console.error("Error deleting cabinet:", error);
      message.error(t("deleteError")); // Translated error message
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentCabinet(null);
    setImageFile(null);
  };

  const viewCabinet = (record) => {
    Modal.info({
      title: t("viewModalTitle"), // Translated modal title
      content: (
        <div>
          <p>
            <strong>{t("nameLabel")}:</strong> {record.name}
          </p>
          <p>
            <strong>{t("titleLabel")}:</strong> {record.title}
          </p>
          <p>
            <strong>{t("imageLabel")}:</strong>{" "}
            {record.img ? (
              <img
                src={`${apiUrl}/${record.img}`}
                alt={record.name}
                style={{ width: "100%", maxHeight: "400px" }}
              />
            ) : (
              t("noImage") // Translated "No Image"
            )}
          </p>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t("confirmDeleteTitle"), // Translated title
      content: t("confirmDeleteContent"), // Translated content
      okText: t("deleteButton"), // Translated button text
      okType: "danger",
      cancelText: t("cancelButton"), // Translated button text
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: t("nameLabel"), // Translated column title
      dataIndex: "name",
      key: "name",
      render: (text) => text.substring(0, 20) + (text.length > 20 ? "..." : ""),
    },
    {
      title: t("titleLabel"), // Translated column title
      dataIndex: "title",
      key: "title",
      render: (text) => text.substring(0, 40) + (text.length > 40 ? "..." : ""),
    },
    {
      title: t("imageLabel"), // Translated column title
      dataIndex: "img",
      key: "img",
      render: (img) =>
        img ? (
          <Image
            width={100}
            src={`${apiUrl}/${img}`}
            alt="Cabinet Image"
            fallback="default-image-url"
          />
        ) : (
          t("noImage") // Translated "No Image"
        ),
    },
    {
      title: t("actionsLabel"), // Translated column title
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            type="default"
            className="bg-blue-600 p-2 text-white"
            onClick={() => viewCabinet(record)}
            style={{ marginRight: 8 }}
          >
            {t("viewButton")} {/* Translated button text */}
          </Button>
          <Button
            type="default"
            className="bg-green-700 p-2 text-white"
            onClick={() => handleUpdate(record)}
            style={{ marginRight: 8 }}
          >
            {t("updateButton")} {/* Translated button text */}
          </Button>
          <Button
            type="default"
            className="bg-red-600 p-2 text-white"
            onClick={() => confirmDelete(record._id)}
            style={{ color: "white" }}
          >
            {t("deleteButton")} {/* Translated button text */}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label={t("nameLabel")} // Translated label
          rules={[{ required: true, message: t("nameRequired") }]} // Translated validation message
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="title"
          label={t("titleLabel")} // Translated label
          rules={[{ required: true, message: t("titleRequired") }]} // Translated validation message
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("imageLabel")}> {/* Translated label */}
          <Upload
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>{t("uploadButton")}</Button> {/* Translated button text */}
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submitButton")} {/* Translated button text */}
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={cabinets}
        rowKey="_id"
        style={{ marginTop: 20 }}
      />

      <Modal
        title={t("updateModalTitle")} // Translated modal title
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item
            name="name"
            label={t("nameLabel")} // Translated label
            rules={[{ required: true, message: t("nameRequired") }]} // Translated validation message
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="title"
            label={t("titleLabel")} // Translated label
            rules={[{ required: true, message: t("titleRequired") }]} // Translated validation message
          >
            <Input />
          </Form.Item>
          <Form.Item label={t("imageLabel")}> {/* Translated label */}
            <input type="file" onChange={handleUpdateFileChange} />
            {currentCabinet && currentCabinet.img && (
              <div style={{ marginTop: 10 }}>
                <img
                  src={`${apiUrl}/${currentCabinet.img}`}
                  alt="Current"
                  style={{ width: "100px", height: "auto" }}
                />
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CabinetManager;