import React, { useState, useEffect } from 'react';
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader'; // Import the Loader component
import Header from './Header';
import { Image } from 'antd'; // Import Ant Design Image component
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined } from '@ant-design/icons'; // Import an icon for the back button
import AOS from 'aos'; // Import AOS for animations
import 'aos/dist/aos.css'; // Import AOS CSS

const DetailedGallery = () => {
  let apiUrl = getApiUrl();
  const { t } = useTranslation();
  const { id } = useParams();
  const [galleryItem, setGalleryItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS for animations
    const fetchGalleryItem = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/gallery-images/${id}`);
        setGalleryItem(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching gallery item:', error);
        setLoading(false);
      }
    };

    fetchGalleryItem();
  }, [id]);

  if (loading) return <Loader />; // Show loader while data is being fetched

  if (!galleryItem) return <div>No data found</div>; // Handle no data scenario

  // Format the date for display
  const formattedDate = new Date(galleryItem.date).toLocaleDateString();

  return (
    <div>
      <Header />
      <div className="mx-auto p-4 lg:p-8 bg-gradient-to-br from-blue-50 to-blue-100 min-h-screen">
        {/* Back Button */}
        <button
          onClick={() => navigate('/gallery')} // Navigate to the gallery page
          className="mb-6 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all duration-300 flex items-center gap-2 shadow-md hover:shadow-lg"
          data-aos="fade-right"
        >
          <ArrowLeftOutlined />
          {t('Back to Gallery')}
        </button>

        {/* Main Content */}
        <div className="bg-white rounded-lg shadow-xl p-6 lg:p-8" data-aos="fade-up">
          <h1 className="text-3xl font-bold mb-6 text-blue-800">{t('Gallery Item Details')}</h1>
          <div className="flex flex-col md:flex-row gap-8">
            {/* Main Image */}
            <div className="md:w-1/2">
              <Image
                alt="Gallery item"
                src={`${apiUrl}/${galleryItem.images[0]}`}
                className="w-full h-auto rounded-lg shadow-md"
                data-aos="zoom-in"
              />
            </div>

            {/* Description and Date */}
            <div className="md:w-1/2 flex flex-col justify-between">
              <div>
                <h2 className="text-2xl font-semibold mb-4 text-blue-700">{t('Description')}</h2>
                <p className="text-gray-700 text-lg leading-relaxed">{galleryItem.description}</p>
              </div>
              <div className="mt-6">
                <p className="text-gray-600 text-lg">
                  <strong>{t('Date Posted')}:</strong> {formattedDate}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Additional Images Section */}
        <div className="mt-12" data-aos="fade-up">
          <h2 className="text-3xl font-bold mb-8 text-blue-800">{t('Additional Images')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {galleryItem.images.slice(1).map((image, index) => (
              <div
                key={index}
                className="relative w-full h-64 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300"
                data-aos="zoom-in"
              >
                <Image
                  alt={`Gallery image ${index + 1}`}
                  src={`${apiUrl}/${image}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedGallery;