import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

const LoginForm = ({ onLogin }) => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
 
  const handleLogin = (event) => {
    event.preventDefault();
    onLogin(username, password);
    
  };

  return (
    <div className="w-full min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md transform transition-all duration-300 hover:scale-105">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
            {t("loginForm.welcomeMessage")}
          </h2>
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}
          <form onSubmit={handleLogin}>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="username"
              >
                {t("loginForm.usernameLabel")}
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                placeholder={t("loginForm.usernamePlaceholder")}
                required
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="password"
              >
                {t("loginForm.passwordLabel")}
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                placeholder={t("loginForm.passwordPlaceholder")}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white py-3 rounded-lg font-semibold hover:from-blue-700 hover:to-purple-700 transition-all duration-300"
            >
              {t("loginForm.loginButton")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;