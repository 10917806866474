import React, { useState, useEffect } from 'react';
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import { Card, Pagination } from 'antd';
import { motion } from 'framer-motion';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import Loader from '../../components/Loader'; // Import the Loader component
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Gallery = () => {
  let apiUrl = getApiUrl();
  const { t } = useTranslation();
  const [galleryItems, setGalleryItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state
  const itemsPerPage = 12;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/gallery-images`);
        // Ensure images paths are updated if needed
        const updatedItems = response.data.map(item => ({
          ...item,
          images: item.images.map(image => image.replace(/\\/g, '/'))
        }));
        setGalleryItems(updatedItems);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching gallery items:', error);
        setLoading(false); // Ensure loading is set to false even on error
      }
    };

    fetchGalleryItems();
  }, []);

  // Calculate the indexes of the items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = galleryItems.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const openDetailPage = (id) => {
    navigate(`/gallery/${id}`);
  };

  // Function to truncate text to a certain length
  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  return (
    <div className=" mx-auto p-4 lg:p-8 bg-blue-50 min-h-screen">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Loader /> {/* Show loader while data is being fetched */}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {currentItems.map((item) => (
              <motion.div
                key={item._id}
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
                className="flex justify-center"
              >
                <Card
                  cover={
                    <div
                      className="relative w-full h-48 cursor-pointer"
                      onClick={() => openDetailPage(item._id)}
                    >
                      <img
                        alt={item.description}
                        src={`${apiUrl}/${item.images[0]}`}
                        className="w-full h-full object-cover"
                      />
                      

                    </div>
                  }
                  className="w-full max-w-xs bg-[#0a4275] text-gray-800 shadow-lg rounded-lg hover:shadow-2xl hover:scale-105 transition-transform"
                  hoverable
                  style={{ borderRadius: '10px' }}
                >
                  <Card.Meta
                    description={
                      <p className="text-white text-center">
                        {truncateText(item.description, 100)}
                      </p>
                    }
                    className="text-center mt-4"
                  />
                </Card>
              </motion.div>
            ))}
          </div>

          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              total={galleryItems.length}
              pageSize={itemsPerPage}
              onChange={onPageChange}
              showSizeChanger={false}
              className="bg-gray-100 rounded-lg p-2 shadow-md"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Gallery;
