import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getApiUrl } from '../../utils/getApiUrl'; // Get API URL
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ChiefExecutive = () => {
  const { t } = useTranslation(); // Initialize translation
  let apiUrl = getApiUrl();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [chiefExecutives, setChiefExecutives] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentChiefExecutive, setCurrentChiefExecutive] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchChiefExecutives();
  }, []);

  const fetchChiefExecutives = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/chief-executive`);
      setChiefExecutives(response.data);
    } catch (error) {
      console.error(t('error.fetchingChiefExecutives'), error);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('title', values.title);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('fax', values.fax || '');
    formData.append('location', values.location);
    formData.append('facebook', values.facebook || '');
    formData.append('twitter', values.twitter || '');
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }

    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };

      if (editId) {
        await axios.patch(`${apiUrl}/api/chief-executive/${editId}`, formData, {
          headers,
        });
        message.success(t('success.updateChiefExecutive'));
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/chief-executive`, formData, { headers });
        message.success(t('success.addChiefExecutive'));
      }
      form.resetFields();
      setFileList([]);
      fetchChiefExecutives();
    } catch (error) {
      console.error(t('error.submittingChiefExecutive'), error);
      message.error(
        editId
          ? t('error.updateChiefExecutive')
          : t('error.addChiefExecutive')
      );
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.image = imageFile;

      const formData = new FormData();
      formData.append('name', updatedData.name);
      formData.append('title', updatedData.title);
      formData.append('phone', updatedData.phone);
      formData.append('email', updatedData.email);
      formData.append('fax', updatedData.fax || '');
      formData.append('location', updatedData.location);
      formData.append('facebook', updatedData.facebook || '');
      formData.append('twitter', updatedData.twitter || '');
      if (imageFile) formData.append('image', updatedData.image);

      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };

      await axios.patch(`${apiUrl}/api/chief-executive/${currentChiefExecutive._id}`, formData, {
        headers,
      });

      message.success(t('success.updateChiefExecutive'));
      setIsModalVisible(false);
      fetchChiefExecutives();
    } catch (error) {
      console.error(t('error.validationFailed'), error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      name: record.name,
      title: record.title,
      phone: record.phone,
      email: record.email,
      fax: record.fax,
      location: record.location,
      facebook: record.facebook,
      twitter: record.twitter,
    });
    setCurrentChiefExecutive(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/api/chief-executive/${id}`, { headers });

      message.success(t('success.deleteChiefExecutive'));
      fetchChiefExecutives();
    } catch (error) {
      console.error(t('error.deletingChiefExecutive'), error);
      message.error(t('error.deleteChiefExecutive'));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentChiefExecutive(null);
    setImageFile(null);
  };

  const viewMessage = (record) => {
    Modal.info({
      title: t('modal.viewChiefExecutive'),
      content: (
        <div>
          <p><strong>{t('form.name')}:</strong> {record.name}</p>
          <p><strong>{t('form.title')}:</strong> {record.title}</p>
          <p><strong>{t('form.phone')}:</strong> {record.phone}</p>
          <p><strong>{t('form.email')}:</strong> {record.email}</p>
          <p><strong>{t('form.fax')}:</strong> {record.fax || 'N/A'}</p>
          <p><strong>{t('form.location')}:</strong> {record.location}</p>
          <p><strong>{t('form.facebook')}:</strong> {record.facebook || 'N/A'}</p>
          <p><strong>{t('form.twitter')}:</strong> {record.twitter || 'N/A'}</p>
          <p><strong>{t('form.image')}:</strong> {record.image ? <img src={`${apiUrl}/${record.image}`} alt={record.name} style={{ width: '100%', maxHeight: '400px' }} /> : t('form.noImage')}</p>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t('modal.confirmDeleteTitle'),
      content: t('modal.confirmDeleteContent'),
      okText: t('modal.delete'),
      okType: 'danger',
      cancelText: t('modal.cancel'),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: t('table.title'),
      dataIndex: 'title',
      key: 'title',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: t('table.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => text.substring(0, 15) + (text.length > 15 ? '...' : ''),
    },
    {
      title: t('table.email'),
      dataIndex: 'email',
      key: 'email',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: t('table.image'),
      dataIndex: 'image',
      key: 'image',
      render: (image) => (
        image ? <Image width={100} src={`${apiUrl}/${image}`} alt="Chief Executive Image" fallback="default-image-url" /> : t('table.noImage')
      ),
    },
    {
      title: t('table.actions'),
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewMessage(record)} style={{ marginRight: 8 }}>
            {t('table.view')}
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            {t('table.update')}
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            {t('table.delete')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="name" label={t('form.name')} rules={[{ required: true, message: t('form.nameRequired') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="title" label={t('form.title')} rules={[{ required: true, message: t('form.titleRequired') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phone" label={t('form.phone')} rules={[{ required: true, message: t('form.phoneRequired') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label={t('form.email')} rules={[{ required: true, message: t('form.emailRequired') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="fax" label={t('form.fax')}>
          <Input />
        </Form.Item>
        <Form.Item name="location" label={t('form.location')} rules={[{ required: true, message: t('form.locationRequired') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="facebook" label={t('form.facebook')}>
          <Input />
        </Form.Item>
        <Form.Item name="twitter" label={t('form.twitter')}>
          <Input />
        </Form.Item>
        <Form.Item label={t('form.image')}>
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>{t('form.uploadImage')}</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">{t('form.submit')}</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={chiefExecutives} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title={t('modal.updateChiefExecutive')}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item name="name" label={t('form.name')} rules={[{ required: true, message: t('form.nameRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="title" label={t('form.title')} rules={[{ required: true, message: t('form.titleRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phone" label={t('form.phone')} rules={[{ required: true, message: t('form.phoneRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label={t('form.email')} rules={[{ required: true, message: t('form.emailRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="fax" label={t('form.fax')}>
            <Input />
          </Form.Item>
          <Form.Item name="location" label={t('form.location')} rules={[{ required: true, message: t('form.locationRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="facebook" label={t('form.facebook')}>
            <Input />
          </Form.Item>
          <Form.Item name="twitter" label={t('form.twitter')}>
            <Input />
          </Form.Item>
          <Form.Item label={t('form.image')}>
            <input type="file" onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ChiefExecutive;