import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import { useTranslation } from "react-i18next";

const TenderManager = () => {
  let apiUrl = getApiUrl();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [tenders, setTenders] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentTender, setCurrentTender] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchTenders();
  }, []);

  const fetchTenders = async () => {
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };
      const response = await axios.get(`${apiUrl}/api/tenders`, { headers });
      setTenders(response.data);
    } catch (error) {
      console.error('Error fetching tenders:', error);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('expiredDate', values.expiredDate.format('YYYY-MM-DD'));
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }

    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };

      if (editId) {
        await axios.patch(`${apiUrl}/api/tenders/${editId}`, formData, { headers });
        message.success(t('tenderManager.messages.updateSuccess'));
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/tenders`, formData, { headers });
        message.success(t('tenderManager.messages.addSuccess'));
      }
      form.resetFields();
      setFileList([]);
      fetchTenders();
    } catch (error) {
      console.error('Error submitting tender data:', error);
      message.error(editId ? t('tenderManager.messages.updateError') : t('tenderManager.messages.addError'));
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.image = imageFile;

      const formData = new FormData();
      formData.append("title", updatedData.title);
      formData.append("description", updatedData.description);
      formData.append("expiredDate", updatedData.expiredDate.format('YYYY-MM-DD'));
      if (imageFile) formData.append("image", updatedData.image);

      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      await axios.patch(`${apiUrl}/api/tenders/${currentTender._id}`, formData, { headers });

      message.success(t('tenderManager.messages.updateSuccess'));
      setIsModalVisible(false);
      fetchTenders();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      title: record.title,
      description: record.description,
      expiredDate: moment(record.expiredDate),
    });
    setCurrentTender(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      await axios.delete(`${apiUrl}/api/tenders/${id}`, { headers });
      message.success(t('tenderManager.messages.deleteSuccess'));
      fetchTenders();
    } catch (error) {
      console.error('Error deleting tender:', error);
      message.error(t('tenderManager.messages.deleteError'));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentTender(null);
    setImageFile(null);
  };

  const viewTender = (record) => {
    Modal.info({
      title: t('tenderManager.modal.viewTitle'),
      content: (
        <div>
          <p><strong>{t('tenderManager.fields.title')}:</strong> {record.title}</p>
          <p><strong>{t('tenderManager.fields.postDate')}:</strong> {moment(record.postDate).format('YYYY-MM-DD')}</p>
          <p><strong>{t('tenderManager.fields.expiredDate')}:</strong> {moment(record.expiredDate).format('YYYY-MM-DD')}</p>
          <p><strong>{t('tenderManager.fields.description')}:</strong> {record.description}</p>
          {record.image && <p><strong>{t('tenderManager.fields.image')}:</strong> <img src={`${apiUrl}/${record.image}`} alt={record.title} style={{ width: '100%', maxHeight: '400px' }} /></p>}
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t('tenderManager.modal.confirmDeleteTitle'),
      content: t('tenderManager.modal.confirmDeleteContent'),
      okText: t('tenderManager.actions.delete'),
      okType: 'danger',
      cancelText: t('tenderManager.actions.cancel'),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: t('tenderManager.fields.title'),
      dataIndex: 'title',
      key: 'title',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: t('tenderManager.fields.postDate'),
      dataIndex: 'postDate',
      key: 'postDate',
      render: (date) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: t('tenderManager.fields.expiredDate'),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      render: (date) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: t('tenderManager.fields.actions'),
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewTender(record)} style={{ marginRight: 8 }}>
            {t('tenderManager.actions.view')}
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            {t('tenderManager.actions.update')}
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            {t('tenderManager.actions.delete')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="title" label={t('tenderManager.fields.title')} rules={[{ required: true, message: t('tenderManager.messages.titleRequired') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label={t('tenderManager.fields.description')} rules={[{ required: true, message: t('tenderManager.messages.descriptionRequired') }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="expiredDate" label={t('tenderManager.fields.expiredDate')} rules={[{ required: true, message: t('tenderManager.messages.expiredDateRequired') }]}>
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item label={t('tenderManager.fields.image')}>
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>{t('tenderManager.actions.uploadImage')}</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">{t('tenderManager.actions.submit')}</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={tenders} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title={t('tenderManager.modal.updateTitle')}
        visible={isModalVisible}
        onOk={handleUpdateSubmit}
        onCancel={handleCancel}
      >
        <Form form={updateForm} layout="vertical" initialValues={currentTender}>
          <Form.Item name="title" label={t('tenderManager.fields.title')} rules={[{ required: true, message: t('tenderManager.messages.titleRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label={t('tenderManager.fields.description')} rules={[{ required: true, message: t('tenderManager.messages.descriptionRequired') }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="expiredDate" label={t('tenderManager.fields.expiredDate')} rules={[{ required: true, message: t('tenderManager.messages.expiredDateRequired') }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item label={t('tenderManager.fields.updateImage')}>
            <input type="file" onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TenderManager;