import React, { useState, useEffect } from "react";
import { Upload, Button, Input, Form, message, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import "antd/dist/reset.css"; // Import Ant Design styles
import { useTranslation } from "react-i18next";
import AOS from "aos"; // Import AOS
import { motion } from "framer-motion"; // Import Framer Motion
import "aos/dist/aos.css"; // Import AOS CSS
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL

const ComplaintForm = () => {
  let apiUrl = getApiUrl();
  console.log("the api url is: " + apiUrl);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 2000 });
  }, []);

  const handleSubmit = async (values) => {
    if (!disclaimerAccepted) {
      message.error("Please accept the disclaimer before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("complaintName", values.name);
    formData.append("complaintSubCity", values.subCity);
    formData.append("complaintWoreda", values.woreda);
    formData.append("complaintHouseNumber", values.houseNumber);
    formData.append("phoneNumber", values.phone);
    formData.append("complaintInstitution", values.institution);
    formData.append("complaintDetail", values.detail);
    formData.append("complaintSolution", values.solution);

    // Append each file in the fileList to formData
    fileList.forEach((file) => {
      formData.append("evidence", file.originFileObj);
    });

    try {
      await axios.post(`${apiUrl}/api/complaints`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Complaint submitted successfully!");
      form.resetFields();
      setFileList([]); // Clear fileList after successful submission
      setDisclaimerAccepted(false); // Reset checkbox state
    } catch (error) {
      console.error("Error submitting complaint:", error);
      message.error("Failed to submit complaint. Please try again.");
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadProps = {
    beforeUpload: () => false, // Prevent automatic upload
    onChange: handleChange,
    fileList,
  };

  return (
    <section className="bg-gradient-to-r from-blue-50 to-blue-100  py-16">
      <div className="max-w-4xl mx-auto px-4">
        <motion.h2
          className="text-2xl text-[#0a4275] text-center lg:text-4xl font-bold dark:text-white mb-8"
          data-aos="fade-up"
        >
          {t(
            "According to Rule 143/2015, Section 3, Article 9, Sub-Articles 1 and 2 Customer Complaint/Complaint Acceptance Form/Form 001"
          )}
        </motion.h2>
        <motion.div
          className="bg-white p-8 rounded-lg shadow-lg"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              name="name"
              label={t("Complainant's Name")}
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>

            <Form.Item
              name="subCity"
              label={t("Sub-City")}
              rules={[{ required: true, message: "Please enter the sub-city" }]}
            >
              <Input placeholder="Enter sub-city" />
            </Form.Item>

            <Form.Item
              name="woreda"
              label={t("Woreda")}
              rules={[{ required: true, message: "Please enter the woreda" }]}
            >
              <Input placeholder="Enter woreda" />
            </Form.Item>

            <Form.Item
              name="houseNumber"
              label={t("House Number")}
              rules={[
                { required: true, message: "Please enter the house number" },
              ]}
            >
              <Input placeholder="Enter house number" />
            </Form.Item>

            <Form.Item
              name="phone"
              label={t("Phone Number")}
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <Input type="tel" placeholder="Enter your phone number" />
            </Form.Item>

            <Form.Item
              name="institution"
              label={t("Name of the institution")}
              rules={[
                { required: true, message: "Please enter the institution" },
              ]}
            >
              <Input placeholder="Enter the institution name" />
            </Form.Item>

            <Form.Item
              name="detail"
              label={t("Complaint Detail")}
              rules={[
                {
                  required: true,
                  message: "Please provide the details of your complaint",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Provide details about your complaint"
              />
            </Form.Item>

            <Form.Item
              name="solution"
              label={t("Complaint Solution")}
              rules={[
                {
                  required: true,
                  message: "Please provide the requested remedy",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Describe the remedy you are requesting"
              />
            </Form.Item>

            <Form.Item name="evidence" label={t("Evidence")}>
              <Upload {...uploadProps} multiple={true} listType="picture">
                <Button icon={<UploadOutlined />}>
                  {t("Upload Evidence")}
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Checkbox
                checked={disclaimerAccepted}
                onChange={(e) => setDisclaimerAccepted(e.target.checked)}
              >
                {t("I have read and accept the disclaimer")}
              </Checkbox>
            </Form.Item>

            <div className="mb-4 text-sm text-gray-700 dark:text-gray-300">
              <span className="bg-red-600 text-white font-bold p-1 rounded-sm mr-2">
                {t("Disclaimer")}
              </span>{" "}
              {t(
                "The petition that I submitted today, according to the accountability regulation 143/2015, part one, paragraph four, by the body authorized to adjudicate in the Hague, by the main audit, by the council's standing committee, by the judges and prosecutors at the Hague administration conference, and whether it has not been seen by the federal institutions. Whether the matter has been investigated or not, the complaints/complaints submitted by the city cabinet, revenue and finance institutions listed above have not been investigated and are not being investigated, and if my submission is false, I confirm that I will be liable according to the provisions of this regulation."
              )}
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-blue-800 text-white"
                disabled={!disclaimerAccepted}
              >
                {t("Submit Complaint")}
              </Button>
            </Form.Item>
          </Form>
        </motion.div>
      </div>
    </section>
  );
};

export default ComplaintForm;
