import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Upload,
  Button,
  Form,
  Input,
  message,
  Table,
  Modal,
  DatePicker,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import { useTranslation } from "react-i18next";

const EventManager = () => {
  let apiUrl = getApiUrl();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [events, setEvents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };
      const response = await axios.get(`${apiUrl}/api/events`, { headers });
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("eventDate", values.eventDate.format("YYYY-MM-DD"));
    formData.append("location", values.location);
    if (fileList.length > 0) {
      formData.append("image", fileList[0].originFileObj);
    }

    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };

      if (editId) {
        await axios.patch(`${apiUrl}/api/events/${editId}`, formData, {
          headers,
        });
        message.success(t("eventManager.messages.updateSuccess"));
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/events`, formData, { headers });
        message.success(t("eventManager.messages.addSuccess"));
      }
      form.resetFields();
      setFileList([]);
      fetchEvents();
    } catch (error) {
      console.error("Error submitting event data:", error);
      message.error(
        editId
          ? t("eventManager.messages.updateError")
          : t("eventManager.messages.addError")
      );
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.image = imageFile;

      const formData = new FormData();
      formData.append("title", updatedData.title);
      formData.append("description", updatedData.description);
      formData.append("eventDate", updatedData.eventDate.format("YYYY-MM-DD"));
      formData.append("location", updatedData.location);
      if (imageFile) formData.append("image", updatedData.image);

      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      await axios.patch(`${apiUrl}/api/events/${currentEvent._id}`, formData, {
        headers,
      });

      message.success(t("eventManager.messages.updateSuccess"));
      setIsModalVisible(false);
      fetchEvents();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      title: record.title,
      description: record.description,
      eventDate: moment(record.eventDate),
      location: record.location,
    });
    setCurrentEvent(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      await axios.delete(`${apiUrl}/api/events/${id}`, { headers });
      message.success(t("eventManager.messages.deleteSuccess"));
      fetchEvents();
    } catch (error) {
      console.error("Error deleting event:", error);
      message.error(t("eventManager.messages.deleteError"));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentEvent(null);
    setImageFile(null);
  };

  const viewEvent = (record) => {
    Modal.info({
      title: t("eventManager.modal.viewTitle"),
      content: (
        <div>
          <p>
            <strong>{t("eventManager.fields.title")}:</strong> {record.title}
          </p>
          <p>
            <strong>{t("eventManager.fields.eventDate")}:</strong>{" "}
            {moment(record.eventDate).format("YYYY-MM-DD")}
          </p>
          <p>
            <strong>{t("eventManager.fields.location")}:</strong>{" "}
            {record.location}
          </p>
          <p>
            <strong>{t("eventManager.fields.description")}:</strong>{" "}
            {record.description}
          </p>
          {record.image && (
            <p>
              <strong>{t("eventManager.fields.image")}:</strong>{" "}
              <img
                src={`${apiUrl}/${record.image}`}
                alt={record.title}
                style={{ width: "100%", maxHeight: "400px" }}
              />
            </p>
          )}
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t("eventManager.modal.confirmDeleteTitle"),
      content: t("eventManager.modal.confirmDeleteContent"),
      okText: t("eventManager.actions.delete"),
      okType: "danger",
      cancelText: t("eventManager.actions.cancel"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: t("eventManager.fields.title"),
      dataIndex: "title",
      key: "title",
      render: (text) => text.substring(0, 20) + (text.length > 20 ? "..." : ""),
    },
    {
      title: t("eventManager.fields.eventDate"),
      dataIndex: "eventDate",
      key: "eventDate",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: t("eventManager.fields.location"),
      dataIndex: "location",
      key: "location",
    },
    {
      title: t("eventManager.fields.actions"),
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            type="default"
            className="bg-blue-600 p-2 text-white"
            onClick={() => viewEvent(record)}
            style={{ marginRight: 8 }}
          >
            {t("eventManager.actions.view")}
          </Button>
          <Button
            type="default"
            className="bg-green-700 p-2 text-white"
            onClick={() => handleUpdate(record)}
            style={{ marginRight: 8 }}
          >
            {t("eventManager.actions.update")}
          </Button>
          <Button
            type="default"
            className="bg-red-600 p-2 text-white"
            onClick={() => confirmDelete(record._id)}
            style={{ color: "white" }}
          >
            {t("eventManager.actions.delete")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label={t("eventManager.fields.title")}
          rules={[
            {
              required: true,
              message: t("eventManager.messages.titleRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="eventDate"
          label={t("eventManager.fields.eventDate")}
          rules={[
            {
              required: true,
              message: t("eventManager.messages.eventDateRequired"),
            },
          ]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          name="location"
          label={t("eventManager.fields.location")}
          rules={[
            {
              required: true,
              message: t("eventManager.messages.locationRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("eventManager.fields.description")}
          rules={[
            {
              required: true,
              message: t("eventManager.messages.descriptionRequired"),
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label={t("eventManager.fields.image")}>
          <Upload
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>
              {t("eventManager.actions.uploadImage")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("eventManager.actions.submit")}
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={events}
        rowKey="_id"
        style={{ marginTop: 20 }}
      />

      <Modal
        title={t("eventManager.modal.updateTitle")}
        visible={isModalVisible}
        onOk={handleUpdateSubmit}
        onCancel={handleCancel}
      >
        <Form form={updateForm} layout="vertical" initialValues={currentEvent}>
          <Form.Item
            name="title"
            label={t("eventManager.fields.title")}
            rules={[
              {
                required: true,
                message: t("eventManager.messages.titleRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="eventDate"
            label={t("eventManager.fields.eventDate")}
            rules={[
              {
                required: true,
                message: t("eventManager.messages.eventDateRequired"),
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="location"
            label={t("eventManager.fields.location")}
            rules={[
              {
                required: true,
                message: t("eventManager.messages.locationRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t("eventManager.fields.description")}
            rules={[
              {
                required: true,
                message: t("eventManager.messages.descriptionRequired"),
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label={t("eventManager.fields.updateImage")}>
            <input type="file" onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EventManager;
