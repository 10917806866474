import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Input, message, Table, Modal } from "antd";
import { getApiUrl } from "../../utils/getApiUrl";

const QuickMessageManager = () => {
  let apiUrl = getApiUrl();
  const [form] = Form.useForm(); // Form for adding/updating
  const [updateForm] = Form.useForm(); // Separate form for updating in the modal
  const [quickMessages, setQuickMessages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentQuickMessage, setCurrentQuickMessage] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchQuickMessages();
  }, []);

  const fetchQuickMessages = async () => {
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };
      const response = await axios.get(`${apiUrl}/api/quickMessages`, {
        headers,
      });
      setQuickMessages(response.data);
    } catch (error) {
      console.error("ፈጣን መልዕክቶችን ማግኘት አልተሳካም።", error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };

      if (editId) {
        await axios.patch(`${apiUrl}/api/quickMessages/${editId}`, values, {
          headers,
        });
        message.success("ፈጣን መልዕክት በተሳካ ሁኔታ ተዘምኗል።");
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/quickMessages`, values, { headers });
        message.success("ፈጣን መልዕክት በተሳካ ሁኔታ ታክሏል።");
      }
      form.resetFields();
      fetchQuickMessages();
    } catch (error) {
      console.error("ፈጣን መልዕክት ማስገባት አልተሳካም።", error);
      message.error(
        editId
          ? "ፈጣን መልዕክት ማዘመን አልተሳካም።"
          : "ፈጣን መልዕክት ማክል አልተሳካም።"
      );
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      message: record.message,
    });
    setCurrentQuickMessage(record);
    setEditId(record._id);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add Authorization header
      };

      await axios.delete(`${apiUrl}/api/quickMessages/${id}`, { headers });

      message.success("ፈጣን መልዕክት በተሳካ ሁኔታ ተሰርዟል።");
      fetchQuickMessages();
    } catch (error) {
      console.error("ፈጣን መልዕክት ማስወገድ አልተሳካም።", error);
      message.error("ፈጣን መልዕክት ማስወገድ አልተሳካም።");
    }
  };

  const handleView = (record) => {
    setCurrentQuickMessage(record);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setCurrentQuickMessage(null);
    setEditId(null);
    updateForm.resetFields(); // Reset the update form fields
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      await handleSubmit(values); // Use the same handleSubmit function for consistency
      setIsModalVisible(false);
    } catch (error) {
      console.error("ማረጋገጫ አልተሳካም።", error);
    }
  };

  const columns = [
    {
      title: "መልዕክት",
      dataIndex: "message",
      key: "message",
      render: (text) => text.substring(0, 20) + (text.length > 20 ? "..." : ""),
    },
    {
      title: "ድርጊቶች",
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            type="default"
            className="bg-blue-600 p-2 text-white"
            onClick={() => handleView(record)}
            style={{ marginRight: 8 }}
          >
            አይት
          </Button>
          <Button
            type="default"
            className="bg-green-700 p-2 text-white"
            onClick={() => handleUpdate(record)}
            style={{ marginRight: 8 }}
          >
            አዘምን
          </Button>
          <Button
            type="default"
            className="bg-red-600 p-2 text-white"
            onClick={() => handleDelete(record._id)}
            style={{ color: "white" }}
          >
            ሰርዝ
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="message"
          label="መልዕክት"
          rules={[
            { required: true, message: "መልዕክት ያስፈልጋል።" },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            አስገባ
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={quickMessages}
        rowKey="_id"
        style={{ marginTop: 20 }}
      />

      {/* Update Modal */}
      <Modal
        title="ፈጣን መልዕክት አዘምን"
        visible={isModalVisible}
        onOk={handleUpdateSubmit}
        onCancel={handleCancel}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item
            name="message"
            label="መልዕክት"
            rules={[
              { required: true, message: "መልዕክት ያስፈልጋል።" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      {/* View Modal */}
      <Modal
        title="ፈጣን መልዕክት አይት"
        visible={isViewModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            ዝጋ
          </Button>,
        ]}
      >
        {currentQuickMessage && (
          <div>
            <p>
              <strong>መልዕክት:</strong>
            </p>
            <p>{currentQuickMessage.message}</p>
            <p>
              <strong>በተፈጠረበት ጊዜ:</strong>{" "}
              {new Date(currentQuickMessage.createdAt).toLocaleString()}
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default QuickMessageManager;