import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import LOGO from "../assets/Kirkos Logo.png";
import { useTranslation } from "react-i18next";
import { Select, Dropdown, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";

const { Option } = Select;

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [quickMessage, setQuickMessage] = useState("");
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    fetch("https://kirkoswebsiteapi.aakirsub.com/api/quickMessages")
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setQuickMessage(data[0].message);
        }
      })
      .catch((error) => console.error("Error fetching quick messages:", error));
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
  };

  const managementMenu = (
    <Menu>
      <Menu.Item key="1">
        <NavLink
          to="/management/chief-executive"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/chief-executive")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
          onClick={closeMenu}
        >
          {t("Chief Executive")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink
          to="/management/cabinets"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/cabinets")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
          onClick={closeMenu}
        >
          {t("Cabinets")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink
          to="/management/structure"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/structure")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
          onClick={closeMenu}
        >
          {t("Structure")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink
          to="/management/woredas"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/woredas")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
          onClick={closeMenu}
        >
          {t("Woredas")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink
          to="/management/offices"
          className={`text-gray-800 ${
            location.pathname.startsWith("/management/offices")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
          onClick={closeMenu}
        >
          {t("Offices")}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const announcementMenu = (
    <Menu>
      <Menu.Item key="1">
        <NavLink
          to="/announcement/tender"
          className={`text-gray-800 ${
            location.pathname.startsWith("/announcement/tender")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
          onClick={closeMenu}
        >
          {t("Tender")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink
          to="/announcement/vacancy"
          className={`text-gray-800 ${
            location.pathname.startsWith("/announcement/vacancy")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
          onClick={closeMenu}
        >
          {t("Vacancy")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink
          to="/announcement/event"
          className={`text-gray-800 ${
            location.pathname.startsWith("/announcement/event")
              ? "bg-gray-200"
              : "hover:text-blue-600"
          }`}
          onClick={closeMenu}
        >
          {t("Event")}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="sticky top-0 z-50 bg-[#0a4275] shadow-md">
      {/* Quick Message Bar */}
      {quickMessage && (
        <div className="sticky top-0 z-50 bg-blue-800 py-1 overflow-hidden whitespace-nowrap">
          <div className="animate-marquee">
            <marquee className="text-white text-xl font-semibold">{quickMessage}</marquee>
          </div>
        </div>
      )}

      <header className="flex items-center justify-between py-4 px-4 sm:px-10 font-[sans-serif] tracking-wide">
        <div className="flex items-center justify-between w-full gap-5">
          {/* Logo */}
          <NavLink to="/" className="flex items-center">
            <img
              src={LOGO}
              alt="logo"
              className="w-16 h-16 sm:w-20 sm:h-20 transition-all duration-300"
            />
            <span className="hidden md:inline text-white font-bold px-2 text-xl md:text-2xl">
              {t("Kirkos Subcity")}
            </span>
          </NavLink>

          {/* Hamburger Icon for Mobile */}
          <button onClick={toggleMenu} className="lg:hidden">
            <FontAwesomeIcon
              icon={isMenuOpen ? faTimes : faBars}
              className="w-7 h-7 text-white"
            />
          </button>

          {/* Mobile Menu */}
          <AnimatePresence>
            {isMenuOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="lg:hidden fixed inset-0 z-40 bg-black bg-opacity-75 transition-opacity mt-10"
                onClick={closeMenu}
              >
                <motion.div
                  initial={{ x: -300 }}
                  animate={{ x: 0 }}
                  exit={{ x: -300 }}
                  className="bg-white w-64 h-full p-6"
                  onClick={(e) => e.stopPropagation()}
                >
                  <ul className="space-y-4">
                    {["Home", "About", "News", "Gallery", "TopPerformers", "Contact", "Compliant"].map(
                      (item, index) => (
                        <li key={index} className="border-b border-gray-300">
                          <NavLink
                            to={`/${item === "Home" ? "" : item.toLowerCase()}`}
                            className="block py-3 px-3 text-gray-700 font-semibold text-lg hover:text-blue-600"
                            onClick={closeMenu}
                          >
                            {t(item)}
                          </NavLink>
                        </li>
                      )
                    )}
                    <li>
                      <Dropdown
                        overlay={managementMenu}
                        trigger={["click"]}
                        className="w-full"
                      >
                        <a className="block py-3 px-3 text-gray-700 font-semibold text-lg cursor-pointer hover:text-blue-600">
                          {t("Management")}{" "}
                          <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                        </a>
                      </Dropdown>
                    </li>
                    <li>
                      <Dropdown
                        overlay={announcementMenu}
                        trigger={["click"]}
                        className="w-full"
                      >
                        <a className="block py-3 px-3 text-gray-700 font-semibold text-lg cursor-pointer hover:text-blue-600">
                          {t("Announcement")}{" "}
                          <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                        </a>
                      </Dropdown>
                    </li>
                  </ul>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Regular Menu - Hidden on Small Screens */}
          <div className="hidden lg:flex items-center space-x-6">
            {["Home", "About", "News", "Gallery"].map((item, index) => (
              <NavLink
                key={index}
                to={`/${item === "Home" ? "" : item.toLowerCase()}`}
                className={({ isActive }) =>
                  isActive ? "text-yellow-500 font-bold" : "text-white hover:text-yellow-300"
                }
              >
                {t(item)}
              </NavLink>
            ))}
            <Dropdown overlay={managementMenu} trigger={["hover"]}>
              <a
                className={`text-white font-bold hover:text-yellow-300 ${
                  location.pathname.startsWith("/management")
                    ? "text-yellow-500"
                    : ""
                }`}
              >
                {t("Management")}{" "}
                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
              </a>
            </Dropdown>
            <Dropdown overlay={announcementMenu} trigger={["hover"]}>
              <a
                className={`text-white font-bold hover:text-yellow-300 ${
                  location.pathname.startsWith("/announcement")
                    ? "text-yellow-500"
                    : ""
                }`}
              >
                {t("Announcement")}{" "}
                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
              </a>
            </Dropdown>
            <NavLink
              to="/topperformers"
              className={({ isActive }) =>
                isActive ? "text-yellow-500 font-bold" : "text-white hover:text-yellow-300"
              }
            >
              {t("TopPerformers")}
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? "text-yellow-500 font-bold" : "text-white hover:text-yellow-300"
              }
            >
              {t("Contact")}
            </NavLink>
            <NavLink
              to="/compliant"
              className={({ isActive }) =>
                isActive ? "text-yellow-500 font-bold" : "text-white hover:text-yellow-300"
              }
            >
              {t("Compliant")}
            </NavLink>
          </div>

          {/* Language Selector */}
          <div className="flex items-center space-x-2">
            <span className="text-sm font-bold text-white">{t("LN")}</span>
            <Select
              defaultValue={i18n.language}
              value={i18n.language}
              onChange={changeLanguage}
              className="text-sm rounded-full font-bold bg-blue-600 text-white hover:bg-blue-700 focus:border-blue-800"
              dropdownClassName="bg-white border border-blue-600 rounded-lg"
            >
              {[
                { value: "am", label: t("Amharic") },
                { value: "en", label: t("English") },
                { value: "or", label: t("Afaan Oromo") },
                { value: "tg", label: t("Tigrinya") },
                { value: "sm", label: t("Somali") },
                { value: "ch", label: t("Chinese") },
                { value: "ar", label: t("Arabic") },
                { value: "sp", label: t("Spanish") },
              ].map((option) => (
                <Option
                  key={option.value}
                  className="p-2 hover:bg-blue-700"
                  value={option.value}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;