import React, { useState, useEffect } from "react";
import axios from "axios";
import { getApiUrl } from "../utils/getApiUrl"; // API URL utility
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import AOS from "aos";
import "aos/dist/aos.css";

const TopPerformersDisplay = () => {
  const { t } = useTranslation();
  const apiUrl = getApiUrl();
  const [performers, setPerformers] = useState([]);
  const [filteredPerformers, setFilteredPerformers] = useState([]);
  const [loading, setLoading] = useState(true); // Set loading to true initially
  const [selectedOffice, setSelectedOffice] = useState("all");

  // Set image height programmatically
  const imageHeight = 350; // You can adjust this value as needed

  // const officeNames = [
  //   "ዋና ስራ አስፈጻሚ ጽ/ቤት",
  //   "አስተዳደርና ፋይናንስ ጽ/ቤት",
  //   "ፋይናንስ ፅህፈት ቤት",
  //   "ምክር ቤት ጽ/ቤት",
  //   "አቃቤ ህግ ጽ/ቤት",
  //   "ሰላምና ፀጥታ ጽ/ቤት",
  //   "ደንብ ማስከበር ጽ/ቤት",
  //   "ፕላንና ልማት ኮሚሽን ጽ/ቤት",
  //   "ህብረት ስራ ጽ/ቤት",
  //   "የንግድ ፅ/ቤት",
  //   "የአ/ አና ከተማ ግብርና ጽ/ቤት",
  //   "የመሬት ልማትና አስተዳደር ጽ/ቤት",
  //   "ደረቅ ቆሻሻ ጽ/ቤት",
  //   "አካባቢ ጥበቃ ጽ/ቤት",
  //   "የከተማ ውበትና አረንጓዴ ልማት ጽ/ቤት",
  //   "ፐብሊክ ሰርቪስ የሰዉ ሀብት አስተዳደር",
  //   "መንግስት ህንጻ ጽ/ቤት",
  //   "ባህልና ቱሪዝም ጽ/ቤት",
  //   "ጤና ጽ/ቤት",
  //   "ኮሚኒኬሽን ጽ/ቤት",
  //   "ትምህርት ጽ/ቤት",
  //   "ሴቶችህጻናትና ማህበራዊ",
  //   "አስተዳደርና ፋይናንስ ጽ/ቤት",
  //   "ዲዛይንና ግንባታ ስራዎች ጽ/ቤት",
  //   "የግንባታ ፈቃድና ቁጥጥር ጽ/ቤት",
  //   "የቤቶች አስተዳደር ጽ/ቤት",
  //   "የወጣቶችና ስፖርት ጽ/ቤት",
  //   "የህብረተሰብ ተሳትፎና በጎ ፈቃድ ማስተባበሪያ ጽ/ቤት",
  //   "የኢኖቬሽንና ቴክኖሎጂ ልማት ጽ/ቤት",
  //   "የቴክኒክና ሙያ ጽ/ቤት",
  //   "የስራ ኢን/ኢንዱስትሪ ልማት ጽ/ቤት",
  //   "የስራና ክህሎት ጽ/ቤት",
  //   "ኢንዱስትሪ ልማት ጽ/ቤት"
  // ];

  const officeNames = [
    "ዋና ስራ አስፈጻሚ ጽ/ቤት",
    "አስተዳደርና ፋይናንስ ጽ/ቤት",
    "ፋይናንስ ፅህፈት ቤት",
    "ምክር ቤት ጽ/ቤት",
    "አቃቤ ህግ ጽ/ቤት",
    "ሰላምና ፀጥታ ጽ/ቤት",
    "ደንብ ማስከበር ጽ/ቤት",
    "ፕላንና ልማት ኮሚሽን ጽ/ቤት",
    "ህብረት ስራ ጽ/ቤት",
    "የንግድ ፅ/ቤት",
    "የአ/ አና ከተማ ግብርና ጽ/ቤት",
    "የመሬት ልማትና አስተዳደር ጽ/ቤት",
    "ደረቅ ቆሻሻ ጽ/ቤት",
    "አካባቢ ጥበቃ ጽ/ቤት",
    "የከተማ ውበትና አረንጓዴ ልማት ጽ/ቤት",
    "ፐብሊክ ሰርቪስ የሰዉ ሀብት አስተዳደር",
    "መንግስት ህንጻ ጽ/ቤት",
    "ባህልና ቱሪዝም ጽ/ቤት",
    "ጤና ጽ/ቤት",
    "ኮሚኒኬሽን ጽ/ቤት",
    "ትምህርት ጽ/ቤት",
    "ሴቶችህጻናትና ማህበራዊ",
    "አስተዳደርና ፋይናንስ ጽ/ቤት",
    "ዲዛይንና ግንባታ ስራዎች ጽ/ቤት",
    "የግንባታ ፈቃድና ቁጥጥር ጽ/ቤት",
    "የቤቶች አስተዳደር ጽ/ቤት",
    "የወጣቶችና ስፖርት ጽ/ቤት",
    "የህብረተሰብ ተሳትፎና በጎ ፈቃድ ማስተባበሪያ ጽ/ቤት",
    "የኢኖቬሽንና ቴክኖሎጂ ልማት ጽ/ቤት",
    "የቴክኒክና ሙያ ጽ/ቤት",
    "የስራ ኢን/ኢንዱስትሪ ልማት ጽ/ቤት",
    "የስራና ክህሎት ጽ/ቤት",
    "ኢንዱስትሪ ልማት ጽ/ቤት"
  ];
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS for animations
    fetchPerformers();
  }, []);

  useEffect(() => {
    filterPerformers();
  }, [selectedOffice, performers]);

  const fetchPerformers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/top-performers`);
      setPerformers(response.data);
    } catch (error) {
      console.error("Error fetching performers:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterPerformers = () => {
    if (selectedOffice === "all") {
      setFilteredPerformers(performers);
    } else {
      const filtered = performers.filter((performer) => performer.office === selectedOffice);
      setFilteredPerformers(filtered);
    }
  };

  const handleOfficeFilter = (value) => {
    setSelectedOffice(value);
  };

  return (
    <div className="p-6 bg-gradient-to-r from-blue-50 to-blue-100 min-h-screen">
      <h2 className="text-center mb-8">
        <span className="font-bold text-4xl text-blue-800">{t("Top Performers")}</span>
      </h2>

      {/* Filter Section */}
      <div className="mb-8">
        <select
          className="w-full md:w-1/3 p-2 rounded-lg shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={(e) => handleOfficeFilter(e.target.value)}
          value={selectedOffice}
        >
          <option value="all">{t("All Offices")}</option>
          {officeNames.map((office, index) => (
            <option key={index} value={office}>
              {office}
            </option>
          ))}
        </select>
      </div>

      {/* Performers Grid */}
      {loading ? (
        <div className="flex justify-center items-center min-h-[50vh]">
          <Loader /> {/* Show loader while data is being fetched */}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredPerformers.map((performer, index) => (
            <div
              key={performer._id}
              className="rounded-lg shadow-lg bg-white transform transition-transform duration-300 hover:scale-105"
              data-aos="fade-up"
            >
              {/* Image */}
              {performer.image ? (
                <img
                  alt="Performer"
                  src={`${apiUrl}/uploads/TopPerformers/${performer.image}`}
                  className="w-full h-72 object-fill rounded-t-lg"
                  style={{ height: `${imageHeight}px` }} // Set image height programmatically
                />
              ) : (
                <div
                  className="flex items-center justify-center bg-gray-100 rounded-t-lg"
                  style={{ height: `${imageHeight}px` }} // Set fallback height programmatically
                >
                  <span className="text-gray-500">No Image</span>
                </div>
              )}

              {/* Details */}
              <div className="p-4 text-center">
                <div className="text-3xl text-yellow-500 mb-4">🏆</div>
                <h3 className="text-xl font-semibold mb-2">{performer.fullName}</h3>
                <p className="text-gray-600 mb-2">{performer.office}</p>
                <p className="text-lg font-bold">
                  {t("Points")}: {performer.point}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TopPerformersDisplay;