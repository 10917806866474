import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Input, message, Table, Modal, Select } from 'antd';
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const { Option } = Select;

const UserManager = () => {
  const { t } = useTranslation(); // Initialize useTranslation hook
  let apiUrl = getApiUrl();
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [formMode, setFormMode] = useState('add'); // 'add' or 'edit'

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };

      const response = await axios.get(`${apiUrl}/api/users`, { headers });
      setUsers(response.data);
    } catch (error) {
      console.error(t('userManagerr.fetchUsersError'), error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };

      if (formMode === 'add') {
        await axios.post(`${apiUrl}/api/users/register`, values, { headers });
        message.success(t('userManagerr.registerSuccess'));
      } else {
        await axios.patch(`${apiUrl}/api/users/${currentUser._id}`, values, { headers });
        message.success(t('userManagerr.updateSuccess'));
      }
      form.resetFields();
      setIsModalVisible(false);
      fetchUsers();
    } catch (error) {
      console.error(t('userManagerr.submitError'), error);
      message.error(t('userManagerr.submitErrorMessage'));
    }
  };

  const handleEdit = (record) => {
    form.setFieldsValue({
      username: record.username,
      role: record.role,
    });
    setCurrentUser(record);
    setFormMode('edit');
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Add the token in the Authorization header
      };

      await axios.delete(`${apiUrl}/api/users/${id}`, { headers });
      message.success(t('userManagerr.deleteSuccess'));
      fetchUsers();
    } catch (error) {
      console.error(t('userManagerr.deleteError'), error);
      message.error(t('userManagerr.deleteErrorMessage'));
    }
  };

  const handlePasswordReset = async (id) => {
    try {
      const newPassword = prompt(t('userManagerr.enterNewPassword')); // Prompt for new password
      if (!newPassword) {
        message.error(t('userManagerr.newPasswordRequired'));
        return;
      }

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.post(
        `${apiUrl}/api/users/reset-password/${id}`,
        { newPassword }, // Send new password in the request body
        { headers }
      );

      message.success(t('userManagerr.passwordResetSuccess'));
      fetchUsers();
    } catch (error) {
      console.error(t('userManagerr.passwordResetError'), error);
      message.error(t('userManagerr.passwordResetErrorMessage'));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentUser(null);
    setFormMode('add');
  };

  const columns = [
    {
      title: t('userManagerr.username'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('userManagerr.role'),
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: t('userManagerr.actions'),
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-red-700 text-white' onClick={() => handleDelete(record._id)} style={{ marginRight: 8 }}>
            {t('userManagerr.delete')}
          </Button>
          <Button type="default" className='bg-green-600 text-white' onClick={() => handlePasswordReset(record._id)}>
            {t('userManagerr.resetPassword')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: 20 }}>
        {t('userManagerr.addUser')}
      </Button>
      <Table columns={columns} dataSource={users} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title={formMode === 'add' ? t('userManagerr.registerUser') : t('userManagerr.updateUser')}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="username" label={t('userManagerr.username')} rules={[{ required: true, message: t('userManagerr.usernameRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label={t('userManagerr.password')} rules={[{ required: formMode === 'add', message: t('userManagerr.passwordRequired') }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name="role" label={t('userManagerr.role')} rules={[{ required: true, message: t('userManagerr.roleRequired') }]}>
            <Select>
              <Option value="compliant">{t('userManagerr.compliant')}</Option>
              <Option value="admin">{t('userManagerr.admin')}</Option>
              <Option value="communication">{t('userManagerr.communication')}</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManager;