import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoginForm from './LoginForm';
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL

const AuthHandler = ({ onLogin }) => {
  
  let apiUrl = getApiUrl();
  const navigate = useNavigate();

  const handleLogin = async (username, password) => {
    console.log("The User Name Is : ", username)
    localStorage.setItem('userName', username)
    try {
      const response = await axios.post(`${apiUrl}/api/users/login`, { username, password });
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.role); // Save role in localStorage
        console.log(response.data)
         //localStorage.setItem("firstname", response.data);
        // localStorage.setItem("lastname", response.data.role); // Save role in localStorage
        localStorage.setItem("userId", response.data.userId); // Save userId in localStorage
        console.log("THE RESPONSE IS ", response);
        console.log("THE RESPONSE IS ", response.data.userId);
        onLogin(response.data.role); // Callback to set authentication state
        navigate("/adminpage"); // Redirect to adminpage
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Invalid username or password");
    }
  };

  return <LoginForm onLogin={handleLogin} />;
};

export default AuthHandler;
