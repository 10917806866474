import React, { useState, useEffect } from "react";
import { getApiUrl } from "../../utils/getApiUrl"; // Get API URL
import { Card, Button, Pagination } from "antd";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import "tailwindcss/tailwind.css";
import axios from "axios";
import Loader from "../../components/Loader"; // Import the Loader component
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const NewsList = () => {
  let apiUrl = getApiUrl();
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state
  const itemsPerPage = 8;

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 1000, once: true });

    // Fetch news items from the backend
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/news`);
        setNewsItems(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchNews();
  }, []);

  // Calculate the indexes of the items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsItems.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Format date in European style (DD/MM/YYYY)
  const formatDate = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 p-6">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Loader />{" "}
        </div> // Show loader while loading
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {currentItems.map((item, index) => (
              <motion.div
                key={index}
                data-aos="fade-up"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, type: "spring" }}
                className="transition-transform transform hover:scale-105"
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
              >
                <Card
                  cover={
                    <div className="relative h-48 overflow-hidden">
                      <img
                        alt={item.title}
                        src={`${apiUrl}/${item.image}`}
                        className="w-full h-full object-fill rounded-t-lg"
                      />
                    </div>
                  }
                  className="shadow-lg rounded-lg bg-white hover:shadow-xl transition-shadow"
                  hoverable
                  style={{ borderRadius: "10px", overflow: "hidden" }}
                >
                  <Card.Meta
                    title={
                      <h2 className="text-xl font-semibold text-blue-800 mb-2">
                        {item.title}
                      </h2>
                    }
                    description={
                      <div>
                        <p className="text-gray-700 mb-4">
                          {item.description.length > 200
                            ? `${item.description.substring(0, 200)}...`
                            : item.description}
                        </p>
                        <p className="text-sm text-gray-500 mb-4">
                          {formatDate(item.date)}
                        </p>
                        <Link to={`/news/${item._id}`}>
                          <Button
                            type="primary"
                            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors"
                          >
                            Read More
                          </Button>
                        </Link>
                      </div>
                    }
                  />
                </Card>
              </motion.div>
            ))}
          </div>
          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              total={newsItems.length}
              pageSize={itemsPerPage}
              onChange={onPageChange}
              showSizeChanger={false}
              className="bg-white rounded-lg p-2 shadow-md"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NewsList;