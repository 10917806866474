import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Upload,
  Button,
  Form,
  Input,
  message,
  Table,
  Modal,
  Image,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getApiUrl } from "../../utils/getApiUrl";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

const GalleryManager = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  let apiUrl = getApiUrl();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchGalleryImages();
  }, []);

  const fetchGalleryImages = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/gallery-images`);
      setGalleryImages(response.data);
    } catch (error) {
      console.error(t("galleryManager.fetchError"), error);
    }
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
    setImageFiles(fileList.map((file) => file.originFileObj));
  };

  const handleUpdateFileChange = (e) => {
    setImageFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("description", values.description);
    imageFiles.forEach((file) => formData.append("images", file));

    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      if (editId) {
        await axios.patch(`${apiUrl}/api/gallery-images/${editId}`, formData, {
          headers,
        });
        message.success(t("galleryManager.updateSuccess"));
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/gallery-images`, formData, { headers });
        message.success(t("galleryManager.addSuccess"));
      }
      form.resetFields();
      setFileList([]);
      setImageFiles([]);
      fetchGalleryImages();
    } catch (error) {
      console.error(t("galleryManager.submitError"), error);
      message.error(
        editId
          ? t("galleryManager.updateFailed")
          : t("galleryManager.addFailed")
      );
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const formData = new FormData();
      formData.append("description", values.description);
      imageFiles.forEach((file) => formData.append("images", file));

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      await axios.patch(
        `${apiUrl}/api/gallery-images/${currentImage._id}`,
        formData,
        {
          headers,
        }
      );
      message.success(t("galleryManager.updateSuccess"));
      setIsModalVisible(false);
      setImageFiles([]);
      fetchGalleryImages();
    } catch (error) {
      console.error(t("galleryManager.validationFailed"), error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      description: record.description,
    });
    setCurrentImage(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/api/gallery-images/${id}`, { headers });
      message.success(t("galleryManager.deleteSuccess"));
      fetchGalleryImages();
    } catch (error) {
      console.error(t("galleryManager.deleteError"), error);
      message.error(t("galleryManager.deleteFailed"));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentImage(null);
    setImageFiles([]);
  };

  const viewImage = (record) => {
    Modal.info({
      title: t("galleryManager.viewImageTitle"),
      content: (
        <div>
          <p>
            <strong>{t("galleryManager.descriptionLabel")}:</strong>{" "}
            {record.description}
          </p>
          {record.images && record.images.length > 0 ? (
            record.images.map((image, index) => (
              <img
                key={index}
                src={`${apiUrl}/${image}`}
                alt={record.description}
                style={{
                  width: "100%",
                  maxHeight: "400px",
                  marginBottom: "10px",
                }}
              />
            ))
          ) : (
            <p>{t("galleryManager.noImage")}</p>
          )}
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t("galleryManager.confirmDeleteTitle"),
      content: t("galleryManager.confirmDeleteContent"),
      okText: t("galleryManager.deleteButton"),
      okType: "danger",
      cancelText: t("galleryManager.cancelButton"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: t("galleryManager.descriptionColumn"),
      dataIndex: "description",
      key: "description",
      render: (text) => text.substring(0, 20) + (text.length > 20 ? "..." : ""),
    },
    {
      title: t("galleryManager.imagesColumn"),
      dataIndex: "images",
      key: "images",
      render: (images) =>
        images && images.length > 0
          ? images.map((image, index) => (
              <Image
                key={index}
                width={100}
                src={`${apiUrl}/${image}`}
                alt="Gallery Image"
                fallback="default-image-url"
              />
            ))
          : t("galleryManager.noImage"),
    },
    {
      title: t("galleryManager.actionsColumn"),
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            type="default"
            className="bg-blue-600 p-2 text-white"
            onClick={() => viewImage(record)}
            style={{ marginRight: 8 }}
          >
            {t("galleryManager.viewButton")}
          </Button>
          <Button
            type="default"
            className="bg-green-700 p-2 text-white"
            onClick={() => handleUpdate(record)}
            style={{ marginRight: 8 }}
          >
            {t("galleryManager.updateButton")}
          </Button>
          <Button
            type="default"
            className="bg-red-600 p-2 text-white"
            onClick={() => confirmDelete(record._id)}
            style={{ color: "white" }}
          >
            {t("galleryManager.deleteButton")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="description"
          label={t("galleryManager.descriptionLabel")}
          rules={[
            { required: true, message: t("galleryManager.descriptionRequired") },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label={t("galleryManager.imagesLabel")}>
          <Upload
            multiple
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>
              {t("galleryManager.uploadButton")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("galleryManager.submitButton")}
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={galleryImages}
        rowKey="_id"
        style={{ marginTop: 20 }}
      />

      <Modal
        title={t("galleryManager.updateModalTitle")}
        visible={isModalVisible}
        onOk={handleUpdateSubmit}
        onCancel={handleCancel}
      >
        <Form form={updateForm} layout="vertical" initialValues={currentImage}>
          <Form.Item
            name="description"
            label={t("galleryManager.descriptionLabel")}
            rules={[
              { required: true, message: t("galleryManager.descriptionRequired") },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label={t("galleryManager.updateImagesLabel")}>
            <input type="file" multiple onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GalleryManager;