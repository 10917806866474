import React, { useEffect, useState } from "react";
import { getApiUrl } from "../../../utils/getApiUrl"; // Get API URL
import { Card, Spin } from "antd";
import {
  FaFacebookF,
  FaTwitter,
  FaPhoneAlt,
  FaEnvelope,
  FaFax,
  FaMapMarkerAlt,
} from "react-icons/fa";
import Loader from "../../Loader"
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const ChiefExecutive = () => {
  let apiUrl = getApiUrl();
  const { t } = useTranslation();
  const [chiefExecutive, setChiefExecutive] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 2000 });

    const fetchChiefExecutive = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/chief-executive`);
        setChiefExecutive(response.data[0]); // Assuming you get an array of chief executives
        setLoading(false);
      } catch (error) {
        console.error("Error fetching chief executive data:", error);
        setLoading(false);
      }
    };

    fetchChiefExecutive();
  }, []);

  if (loading) {
    return <Loader/>;
  }

  if (!chiefExecutive) {
    return <div>No Chief Executive data found</div>;
  }

  return (
    <div className="mx-auto p-4 md:p-8 flex flex-col md:flex-row gap-8 items-center bg-gradient-to-r from-blue-500 to-teal-400 text-white shadow-lg">
      {/* Image and Social Media Section */}
      <div
        className="flex flex-col items-center w-full md:w-1/2 mb-6 md:mb-0"
        data-aos="fade-right"
      >
        <motion.img
          className="w-[70vh] h-[40vh] sm:h-[50vh] md:h-[60vh] rounded-lg border-4 border-white shadow-lg"
          src={`${apiUrl}/${chiefExecutive.image}`}
          alt="Chief Executive"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        />
        <div className="mt-6 flex flex-col items-center">
          <h1 className="text-white font-bold text-xl lg:text-2xl mb-4">
            {t('FOLLOW ME ON SOCIAL MEDIA')}
          </h1>
          <div className="flex gap-4">
            <a
              href={chiefExecutive.facebook || "https://facebook.com"}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:scale-110 transform transition-transform"
            >
              <FaFacebookF className="text-2xl sm:text-3xl bg-white text-blue-600 p-2 rounded-full" />
            </a>
            <a
              href={chiefExecutive.twitter || "https://twitter.com"}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:scale-110 transform transition-transform"
            >
              <FaTwitter className="text-2xl sm:text-3xl bg-white text-blue-400 p-2 rounded-full" />
            </a>
          </div>
        </div>
      </div>

      {/* Details Section */}
      <div className="w-full md:w-1/2" data-aos="fade-left">
        <Card className="bg-white text-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-yellow-600 mb-4">
            {chiefExecutive.name}
          </h2>
          <h3 className="text-lg sm:text-xl font-semibold text-gray-700 mb-6">
            {chiefExecutive.title}
          </h3>
          <div className="space-y-4">
            <p className="text-sm sm:text-lg flex items-center">
              <FaPhoneAlt className="mr-3 text-yellow-600 text-xl" />
              <strong>{t('Office Tel:')}</strong> {chiefExecutive.phone}
            </p>
            <p className="text-sm sm:text-lg flex items-center">
              <FaEnvelope className="mr-3 text-yellow-600 text-xl" />
              <strong>{t('Email:')}</strong> {chiefExecutive.email}
            </p>
            <p className="text-sm sm:text-lg flex items-center">
              <FaFax className="mr-3 text-yellow-600 text-xl" />
              <strong>{t('Fax:')}</strong> {chiefExecutive.fax || 'N/A'}
            </p>
            <p className="text-sm sm:text-lg flex items-center">
              <FaMapMarkerAlt className="mr-3 text-yellow-600 text-xl" />
              <strong>{t('Location:')}</strong> {chiefExecutive.location}
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ChiefExecutive;