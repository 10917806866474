import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Upload,
  Button,
  Form,
  Input,
  message,
  Table,
  Modal,
  Image,
  Spin,
  Row,
  Col,
  Select,
} from "antd";
import { UploadOutlined, EditOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { getApiUrl } from "../../utils/getApiUrl"; // API URL utility
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const { Option } = Select;

const TopPerformerManager = () => {
  const { t } = useTranslation(); // Initialize useTranslation hook
  let apiUrl = getApiUrl();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [performers, setPerformers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentPerformer, setCurrentPerformer] = useState(null);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);

  // List of 20 random office names
  const officeNames = [
    "ዋና ስራ አስፈጻሚ ጽ/ቤት",
    "አስተዳደርና ፋይናንስ ጽ/ቤት",
    "ፋይናንስ ፅህፈት ቤት",
    "ምክር ቤት ጽ/ቤት",
    "አቃቤ ህግ ጽ/ቤት",
    "ሰላምና ፀጥታ ጽ/ቤት",
    "ደንብ ማስከበር ጽ/ቤት",
    "ፕላንና ልማት ኮሚሽን ጽ/ቤት",
    "ህብረት ስራ ጽ/ቤት",
    "የንግድ ፅ/ቤት",
    "የአ/ አና ከተማ ግብርና ጽ/ቤት",
    "የመሬት ልማትና አስተዳደር ጽ/ቤት",
    "ደረቅ ቆሻሻ ጽ/ቤት",
    "አካባቢ ጥበቃ ጽ/ቤት",
    "የከተማ ውበትና አረንጓዴ ልማት ጽ/ቤት",
    "ፐብሊክ ሰርቪስ የሰዉ ሀብት አስተዳደር",
    "መንግስት ህንጻ ጽ/ቤት",
    "ባህልና ቱሪዝም ጽ/ቤት",
    "ጤና ጽ/ቤት",
    "ኮሚኒኬሽን ጽ/ቤት",
    "ትምህርት ጽ/ቤት",
    "ሴቶችህጻናትና ማህበራዊ",
    "አስተዳደርና ፋይናንስ ጽ/ቤት",
    "ዲዛይንና ግንባታ ስራዎች ጽ/ቤት",
    "የግንባታ ፈቃድና ቁጥጥር ጽ/ቤት",
    "የቤቶች አስተዳደር ጽ/ቤት",
    "የወጣቶችና ስፖርት ጽ/ቤት",
    "የህብረተሰብ ተሳትፎና በጎ ፈቃድ ማስተባበሪያ ጽ/ቤት",
    "የኢኖቬሽንና ቴክኖሎጂ ልማት ጽ/ቤት",
    "የቴክኒክና ሙያ ጽ/ቤት",
    "የስራ ኢን/ኢንዱስትሪ ልማት ጽ/ቤት",
    "የስራና ክህሎት ጽ/ቤት",
    "ኢንዱስትሪ ልማት ጽ/ቤት"
  ];

  useEffect(() => {
    fetchPerformers();
  }, []);

  const fetchPerformers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/top-performers`);
      setPerformers(response.data);
    } catch (error) {
      console.error("Error fetching performers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("fullName", values.fullName);
    formData.append("office", values.office);
    formData.append("point", values.point);
    if (fileList.length > 0) {
      formData.append("image", fileList[0].originFileObj);
    }

    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      setLoading(true);
      if (editId) {
        await axios.patch(`${apiUrl}/api/top-performers/${editId}`, formData, { headers });
        message.success(t('topPerformerManager.updateSuccess'));
        setEditId(null);
      } else {
        await axios.post(`${apiUrl}/api/top-performers`, formData, { headers });
        message.success(t('topPerformerManager.addSuccess'));
      }
      form.resetFields();
      setFileList([]);
      fetchPerformers();
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error submitting performer data:", error);
      message.error(editId ? t('topPerformerManager.updateError') : t('topPerformerManager.addError'));
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (performer) => {
    setEditId(performer._id);
    setCurrentPerformer(performer);
    setIsModalVisible(true);
    form.setFieldsValue({
      fullName: performer.fullName,
      office: performer.office,
      point: performer.point,
    });
  };

  const handleView = (performer) => {
    setCurrentPerformer(performer);
    setIsViewModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      setLoading(true);
      await axios.delete(`${apiUrl}/api/top-performers/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success(t('topPerformerManager.deleteSuccess'));
      fetchPerformers();
    } catch (error) {
      console.error("Error deleting performer:", error);
      message.error(t('topPerformerManager.deleteError'));
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t('topPerformerManager.confirmDeleteTitle'),
      content: t('topPerformerManager.confirmDeleteContent'),
      okText: t('topPerformerManager.delete'),
      okType: "danger",
      cancelText: t('topPerformerManager.cancel'),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    { title: t('topPerformerManager.fullName'), dataIndex: "fullName", key: "fullName" },
    { title: t('topPerformerManager.office'), dataIndex: "office", key: "office" },
    { title: t('topPerformerManager.point'), dataIndex: "point", key: "point" },
    {
      title: t('topPerformerManager.image'),
      dataIndex: "image",
      key: "image",
      render: (image) =>
        image ? <Image width={100} src={`${apiUrl}/uploads/TopPerformers/${image}`} alt="Performer Image" /> : t('topPerformerManager.noImage'),
    },
    {
      title: t('topPerformerManager.actions'),
      key: "actions",
      render: (_, record) => (
        <Row gutter={8}>
          <Col>
            <Button
              icon={<EyeOutlined />}
              onClick={() => handleView(record)}
              type="primary"
            >
              {t('topPerformerManager.view')}
            </Button>
          </Col>
          <Col>
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
              type="default"
            >
              {t('topPerformerManager.edit')}
            </Button>
          </Col>
          <Col>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => confirmDelete(record._id)}
              type="danger"
            >
              {t('topPerformerManager.delete')}
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: 20 }}>
        {t('topPerformerManager.addPerformer')}
      </Button>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={performers}
          rowKey="_id"
          bordered
          pagination={{ pageSize: 10 }}
        />
      </Spin>

      {/* Add & Edit Modal */}
      <Modal
        title={editId ? t('topPerformerManager.editPerformer') : t('topPerformerManager.addPerformer')}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="fullName"
            label={t('topPerformerManager.fullName')}
            rules={[{ required: true, message: t('topPerformerManager.fullNameRequired') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="office"
            label={t('topPerformerManager.office')}
            rules={[{ required: true, message: t('topPerformerManager.officeRequired') }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={t('topPerformerManager.selectOffice')}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {officeNames.map((office, index) => (
                <Option key={index} value={office}>
                  {office}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="point"
            label={t('topPerformerManager.point')}
            rules={[{ required: true, message: t('topPerformerManager.pointRequired') }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item label={t('topPerformerManager.image')}>
            <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false}>
              <Button icon={<UploadOutlined />}>{t('topPerformerManager.uploadImage')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editId ? t('topPerformerManager.update') : t('topPerformerManager.submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* View Modal */}
      <Modal
        title={t('topPerformerManager.performerDetails')}
        visible={isViewModalVisible}
        onCancel={() => setIsViewModalVisible(false)}
        footer={null}
      >
        {currentPerformer && (
          <div>
            <p>
              <strong>{t('topPerformerManager.fullName')}:</strong> {currentPerformer.fullName}
            </p>
            <p>
              <strong>{t('topPerformerManager.office')}:</strong> {currentPerformer.office}
            </p>
            <p>
              <strong>{t('topPerformerManager.point')}:</strong> {currentPerformer.point}
            </p>
            {currentPerformer.image && (
              <Image width={200} src={`${apiUrl}/uploads/TopPerformers/${currentPerformer.image}`} alt="Performer" />
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default TopPerformerManager;